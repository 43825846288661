// COMMIT
export const SAVE_USER = "SAVE_USER";
export const SAVE_ACCOUNT_INFO = "SAVE_ACCOUNT_INFO";
export const SAVE_PERMISSIONS = "SAVE_PERMISSIONS";
export const SAVE_ROLES = "SAVE_ROLES";
export const SAVE_ALT_COINS = "SAVE_ALT_COINS";
export const SAVE_JWT_TOKEN = "SAVE_JWT_TOKEN";
export const SAVE_MERCHANT_PERMISSIONS = "SAVE_MERCHANT_PERMISSIONS";
export const SAVE_TRANSACTIONS = "SAVE_TRANSACTIONS";
export const SAVE_PAGE = "SAVE_PAGE";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const START_BTN_LOADING = "START_BTN_LOADING";
export const STOP_BTN_LOADING = "STOP_BTN_LOADING";
export const SAVE_DASHBOARD_FILTERS = "SAVE_DASHBOARD_FILTERS";
export const SAVE_EXCHANGE_LIST = "SAVE_EXCHANGE_LIST";
export const SAVE_LOCALES = "SAVE_LOCALES";
export const SAVE_TRANSFERWISE_CONFIG = "SAVE_TRANSFERWISE_CONFIG";
export const SAVE_COUNTRIES = "SAVE_COUNTRIES";
export const SAVE_MERCHANTS = "SAVE_MERCHANTS";
export const SAVE_NOTIFICATIONS = "SAVE_NOTIFICATIONS";
export const SAVE_CONVERTER = "SAVE_CONVERTER";
export const RESET = "RESET";
export const SAVE_BALANCE_SHEET_BUY_ENABLED_ONLINE =
  "SAVE_BALANCE_SHEET_BUY_ENABLED_ONLINE";
export const SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE =
  "SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE";
export const SAVE_BALANCE_SHEET_BUY_ENABLED_OFFLINE =
  "SAVE_BALANCE_SHEET_BUY_ENABLED_OFFLINE";
export const SAVE_BALANCE_SHEET_BUY_DISABLED_OFFLINE =
  "SAVE_BALANCE_SHEET_BUY_DISABLED_OFFLINE";
export const SAVE_BALANCE_SHEET_BUY_BOTH_ONLINE =
  "SAVE_BALANCE_SHEET_BUY_BOTH_ONLINE";
export const SAVE_BALANCE_SHEET_BUY_BOTH_OFFLINE =
  "SAVE_BALANCE_SHEET_BUY_BOTH_OFFLINE";
export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";
export const SET_EXTERNAL_PAYMENT_PROVIDERS = "SET_EXTERNAL_PAYMENT_PROVIDERS";
export const SET_PAYOUT_TYPES = "SET_PAYOUT_TYPES";
export const SAVE_IS_EXPANDED = "SAVE_IS_EXPANDED";
export const SET_IS_PRIVACY_MODE = "SET_IS_PRIVACY_MODE";
export const SAVE_MERCHANTS_WITH_KEYS = "SAVE_MERCHANTS_WITH_KEYS";
export const SAVE_SYMBOLS = "SAVE_SYMBOLS";
export const SAVE_TICKERS = "SAVE_TICKERS";
export const SAVE_TYPES = "SAVE_TYPES";

// ACTIONS
export const PERSIST_AUTH = "PERSIST_AUTH";
export const ACCOUNT_INFO = "ACCOUNT_INFO";
export const SAVE_ACCOUNT = "SAVE_ACCOUNT";
export const PERMISSIONS = "PERMISSIONS";
export const ROLES = "ROLES";
export const EXCHANGE_LIST = "EXCHANGE_LIST";
export const TRANSFERWISE_CONFIG = "TRANSFERWISE_CONFIG";
export const LIST_LOCALES = "LIST_LOCALES";
export const LIST_COUNTRIES = "LIST_COUNTRIES";
export const LIST_MERCHANTS = "LIST_MERCHANTS";
export const SET_CART_STATUSES = "SET_CART_STATUSES";
export const LOGOUT = "LOGOUT";
