<template>
  <header class="main-header">
    <!-- Logo -->
    <a class="logo" href="javascript:void(0)" @click="reload()">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <span class="logo-mini">
        <img alt="CTP Admin" src="/assets/img/logo-mini.svg" />
      </span>
      <!-- logo for regular state and mobile devices -->
      <span class="logo-lg">
        <img alt="CTP Admin" src="/assets/img/logo.svg" />
      </span>
    </a>

    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top">
      <!-- Sidebar toggle button-->
      <a class="sidebar-toggle" data-toggle="push-menu" href="javascript:void(0)" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>
      <!-- Navbar Right Menu -->
      <div class="flex align-items-center justify-between">
        <div class="header-search">
          <div class="input-group d-flex lg">
            <input v-model="keyword" aria-label="" class="form-control flex-grow-1" placeholder="Search..."
              style="max-width: 180px;" type="search" />
            <div class="input-group-btn">
              <button class="btn btn-primary btn-flat" @click="search()">Search</button>
            </div>
          </div>
          <div class="dropdown sm">
            <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">
              <i class="fa fa-search"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-left" style="max-width: 320px">
              <li class="dropdown-item px-15">
                <input v-model="keyword" aria-label="" class="form-control" placeholder="Search..." type="text"
                  @keyup.enter="search()" />
              </li>
            </ul>
          </div>
        </div>
        <div class="px-15 flex-grow-1">
          <converter></converter>
        </div>
        <ul class="nav navbar-nav flex">
          <li class="dropdown notifications-menu">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#">
              <i class="fa fa-bell-o"></i>
              <span class="label label-warning">{{ notifications.length }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right" style="max-width: 400px">
              <li class="header">
                You have {{ notifications.length }}
                notification{{ notifications.length === 0 || notifications.length > 1 ? 's' : '' }}
              </li>
              <li>
                <!-- inner menu: contains the actual data -->
                <ul class="menu">
                  <li v-for="(notification, index) in notifications" :key="index"
                    class="flex items-center justify-between px-8 py-2">
                    <span>
                      {{ notification.message + ' - ' + dateDiffToHuman(notification.date) }}</span>
                    <i class="fa fa-circle-o text-gray pointer" @click="markReadAndRedirect(notification)"></i>
                  </li>
                </ul>
              </li>
              <li v-if="notifications.length > 0" class="footer">
                <a href="javascript:void(0)" @click="clearAllNotifications()">Clear All</a>
              </li>
            </ul>
          </li>
          <!-- User Account: style can be found in dropdown.less -->
          <li class="dropdown user user-menu">
            <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">
              <span class="mr-8">{{ currentUser.Username }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <!-- Menu Footer-->
              <li class="user-footer">
                <a @click="logout()">Sign out</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";
import { SAVE_NOTIFICATIONS, SET_SEARCH_KEYWORD } from "../../store/keys";
import Converter from "../Converter";

export default {
  name: "va-navibar",
  components: { Converter },
  data() {
    return {
      keyword: "",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "notifications",
    ]),
  },
  watch: {
    keyword(val) {
      this.$store.commit(SET_SEARCH_KEYWORD, val);
      if (this.$router.currentRoute.name !== "dashboard") {
        this.$router.push({
          name: "dashboard",
          query: { searching: true },
        });
      }
    },
  },
  methods: {
    search() {
      if (this.keyword) {
        this.$store.commit(SET_SEARCH_KEYWORD, "");
        setTimeout(() => {
          this.$store.commit(SET_SEARCH_KEYWORD, this.keyword);
          if (this.$router.currentRoute.name !== "dashboard") {
            this.$router.push({
              name: "dashboard",
              query: { searching: true },
            });
          }
        }, 100);
      }
    },
    reload() {
      window.location.reload();
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    markReadAndRedirect(notification) {
      this.copyToClipboard(notification.message);
      const notificationsList = [...this.notifications];
      const index = this._.findIndex(notificationsList, { id: notification.id });
      if (index !== -1) {
        notificationsList.splice(index, 1);
        this.$store.commit(SAVE_NOTIFICATIONS, notificationsList);
        if (notification.id) {
          this.$router.push({
            name: "transaction-detail",
            params: { id: notification.id },
          });
        }
      }
    },
    clearAllNotifications() {
      this.$store.commit(SAVE_NOTIFICATIONS, []);
    },
    dateDiffToHuman(date) {
      if (date) {
        return this.tz(date, "UTC").fromNow();
      }
      return "";
    },
    logout() {
      logout(true);
    },
  },
  mounted() {
    $(document).on("click", ".notifications-menu .dropdown-menu", (e) => {
      e.stopPropagation();
    });
  },
};
</script>
<style lang="scss" scoped>
.dropdown-menu {
  width: auto !important;
  min-width: 280px !important;
  box-shadow: 0 0 0 1px rgba(136, 152, 170, .1), 0 15px 35px 0 rgba(49, 49, 93, .1), 0 5px 15px 0 rgba(0, 0, 0, .08);
  border-radius: 0.375rem !important;
  margin-right: 0.25rem !important;
  background-color: #ffffff;

  .user-footer {
    background-color: #ffffff !important;
  }
}

.dropdown-menu::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -14px;
  ;
  z-index: 1;
  width: 21px;
  height: 21px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSI5Ij48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiM4ODk4QUEiIGZpbGwtb3BhY2l0eT0iLjEiIGQ9Ik0xIDkuMDkyaDE5bC02LjQwMi02Ljc0Yy0xLjcxNy0xLjgwNi00LjQ4NS0xLjgtNi4xOTYgMEwxIDkuMDkzek0yMC4zNDIgOGwtNi4wMi02LjMzNmMtMi4xMDgtMi4yMi01LjUzOC0yLjIxOC03LjY0NSAwTC42NTggOGgxOS42ODR6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTcuNDAyIDIuMzUzYzEuNzExLTEuODAxIDQuNDgtMS44MDcgNi4xOTYgMEwyMCA5LjA5M0gxbDYuNDAyLTYuNzR6Ii8+PC9nPjwvc3ZnPg==) no-repeat 50%;
}

.dropdown-menu-right::before {
  right: 12px !important;
}

.dropdown-menu-left::before {
  left: 3px;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.header-search {
  margin-left: 15px;

  .lg {
    display: none;
  }

  .sm {
    display: block;
  }

  .fa {
    color: #303030;
  }

  @media (min-width: 1366px) {
    .lg {
      display: flex;
    }

    .sm {
      display: none;
    }
  }
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.py-2 {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
