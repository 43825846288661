module.exports = [
  {
    type: "item",
    isHeader: true,
    name: "MAIN NAVIGATION",
  },
  {
    type: "item",
    icon: "fa fa-dashboard",
    name: "Dashboard",
    router: {
      name: "dashboard",
    },
    query: {
      reload: true,
    },
  },
  {
    type: "tree",
    icon: "fa fa-users",
    name: "Account",
    items: [
      {
        type: "item",
        icon: "fa fa-users",
        name: "Users",
        router: {
          name: "users",
        },
        permission: "view-users",
      },
      {
        type: "item",
        icon: "fa fa-users",
        name: "KYC Users",
        router: {
          name: "kyc",
        },
        permission: "kyc-commander",
      },
      {
        type: "item",
        icon: "fa fa-users",
        name: "Roles",
        router: {
          name: "roles",
        },
        permission: "view-roles",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-tasks",
    name: "Core Functions",
    items: [
      {
        type: "item",
        icon: "fa fa-dollar",
        name: "Payouts",
        router: {
          name: "payouts",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-dollar",
        name: "Balance Sheet",
        router: {
          name: "balance-sheet",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-bitcoin",
        name: "Coin Health",
        router: {
          name: "coin-health",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "VTokens",
        router: {
          name: "vtokens",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "XChange Search",
        router: {
          name: "xchange-search",
        },
        permission: "admin-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-bar-chart",
    name: "Analytics",
    items: [
      {
        type: "item",
        icon: "fa fa-bar-chart",
        name: "Infolytics",
        router: {
          name: "analytics",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-money",
        name: "Transactions",
        router: {
          name: "transactions",
        },
        permissions: "view-transactions",
      },
      {
        type: "item",
        icon: "fa fa-bar-chart",
        name: "Charts",
        router: {
          name: "charts",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "AltCoin Stats",
        router: {
          name: "altcoin-stats",
        },
        permission: "admin-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-bullhorn",
    name: "Marketing",
    items: [
      {
        type: "item",
        icon: "fa fa-bullhorn",
        name: "Email Marketing",
        router: {
          name: "marketing",
        },
        permission: "marketing-commander",
      },
      {
        type: "item",
        icon: "fa fa-bullhorn",
        name: "News",
        router: {
          name: "news",
        },
        permission: "marketing-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-files-o",
    name: "Files",
    items: [
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Market POS Files",
        router: {
          name: "market-pos-files",
        },
        permissions: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Market Files",
        router: {
          name: "market-files",
        },
        permissions: "admin-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-gears",
    name: "Tools",
    items: [
      {
        type: "item",
        icon: "fa fa-server",
        name: "Tomcat Production",
        link: "https://cointopay.com/manager",
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-server",
        name: "Tomcat Artemis",
        link: "https://artemis.cointopay.com/manager",
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-server",
        name: "Artemis Trading Config",
        router: {
          name: "trading-config",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-server",
        name: "Freshdesk",
        link: "https://cointopay.freshdesk.com/support/login",
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-globe",
        name: "Production Web",
        link: "https://cointopay.com",
      },
      {
        type: "item",
        icon: "fa fa-bitcoin",
        name: "reflextrader.com",
        link: "https://exchange.cointopay.com",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-clock-o",
    name: "Scheduled",
    items: [
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Scheduling Web",
        router: {
          name: "platform-scheduling",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Scheduling SQL",
        router: {
          name: "platform-scheduling-sql",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Transactions",
        router: {
          name: "transaction-scheduling",
        },
        permission: "admin-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-files-o",
    name: "Pooling",
    items: [
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "XRP Pool",
        router: {
          name: "transaction-xrp-pool",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Ether Pool",
        router: {
          name: "transaction-ether-pool",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Offline Pool",
        router: {
          name: "xchange-offline-pool",
        },
        permission: "admin-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-bar-chart",
    name: "Audit",
    items: [
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Tasks Audit",
        router: {
          name: "transaction-scheduling-tasks",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "External Audit",
        router: {
          name: "audit",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "TradeFlex Audit",
        router: {
          name: "trade-flex",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-line-chart",
        name: "FIU XML Reports",
        router: {
          name: "reports",
        },
        permission: "view-transactions",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "PayPal Audit",
        router: {
          name: "paypal-audit",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Trade Bots",
        router: {
          name: "trade-bots",
        },
        permission: "admin-commander",
      },
    ],
  },
  {
    type: "tree",
    icon: "fa fa-files-o",
    name: "Misc",
    items: [
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Licensing",
        router: {
          name: "platform-licensing",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Autonomy",
        router: {
          name: "merchant-autonomy",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Merchant Fees Custom",
        router: {
          name: "merchant-fees-custom",
        },
        permission: "admin-commander",
      },
      {
        type: "item",
        icon: "fa fa-file-o",
        name: "Merchant CC",
        router: {
          name: "merchant-cc",
        },
        permission: "admin-commander",
      },
    ],
  },
];
