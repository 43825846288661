<template>
  <div class="dropdown ml-8">
    <a class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
      {{ typesList && typesList.filter(s => s.selected).length || 0 }} options selected
    </a>
    <div class="dropdown-menu">
      <form>
        <a class="dropdown-item" href="#">
          <label class="d-block">
            <input type="checkbox" class="form-checkbox" v-model="allTypes" :checked="allTypes" />
            <span class="ml-3">Select All</span>
          </label>
        </a>
        <a class="dropdown-item" href="#" v-for="(type, index) in typesList" :key="index">
          <label class="d-block">
            <input type="checkbox" class="form-checkbox" v-model="type.selected" :checked="type.selected" />
            <span class="ml-3">{{ type.label }}</span>
          </label>
        </a>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_TYPES } from "../store/keys";

export default {
  name: "TypesDropdown",
  data() {
    return {
      typesList: [],
      allTypes: true,
    };
  },
  props: {
    reset: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "types",
    ]),
  },
  watch: {
    reset(val) {
      this.types = this.types.map(s => {
        return {
          ...s,
          selected: true,
        };
      });
    },
    types: {
      handler(newVal) {
        this.typesList = newVal;
      },
      deep: true,
    },
    typesList: {
      handler(newVal) {
        this.$emit("types", newVal.filter(s => s.selected));
      },
      deep: true,
    },
    allTypes(newVal) {
      this.typesList = this.typesList.map(s => {
        return {
          ...s,
          selected: newVal,
        };
      });
      this.$emit("types", this.typesList.filter(s => s.selected));
    },
  },
  methods: {
    fetchTypesList() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=TypesList`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        const types = response.data.data.map(i => {
          return {
            id: i.toLowerCase(),
            label: i,
            selected: true,
          };
        });
        this.$store.commit(SAVE_TYPES, types);
      }).catch(error => {
        console.log(error);
      });
    },
  },
  beforeMount() {
    this.fetchTypesList();
  },
};
</script>

<style scoped lang="scss">
.dropdown-menu {
  padding: 4px 8px;
  min-width: 220px;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 16px;

  a {
    display: block;
    cursor: pointer;
    margin-bottom: 1px;

    label {
      margin: 0;
      padding: 2px 4px;
      cursor: pointer;
      color: #404040;
      font-weight: normal;
    }

    :hover {
      background-color: #efefef;
    }
  }
}
</style>
