<template>
  <div>
    <div class="box box-info" v-if="transactionFailureCauses">
      <div class="box-header">
        <div class="flex items-center justify-between">
          <h3 class="box-title">Transaction Failure</h3>
          <div style="position: relative;">
            <input style="width: 280px" v-model="keywordFailure" aria-label="Search" id="searchFailure"
              class="form-control" placeholder="Search..." type="search"
              @keyup="searchTransactionFailureCauses($event)" />
            <i v-if="keywordFailure" class="fa fa-close" style="position: absolute;right: 10px;top: 9px"
              @click="keywordFailure = ''; searchTransactionFailureCauses($event)"></i>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="transactionFailureCauses && transactionFailureCauses.records.length" class="footer">
          <pagination :meta="transactionFailureCauses.meta" @pageChange="updatePageTF"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-condensed table-striped mb-0">
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>AltCoin</th>
                <th>Failure</th>
                <th>Address</th>
                <th>CreatedOn</th>
                <th>UpdatedOn</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(record, index) in transactionFailureCauses.records" :key="index">
                <td>
                  <span class="flex items-center gap-2">
                    <span class="dot red" v-if="isNotificationNew(record.CreatedOn)"></span>
                    <span :class="`dot pointer ${record.Sticky ? 'yellow' : 'gray'}`"
                      @click="toggleSticky(record)"></span>
                    <i :class="`fa fa-comment pointer ${record.Notes ? 'text-yellow' : 'text-gray'}`"
                      @click="updateNotes(record)"></i>
                  </span>
                </td>
                <td>
                  {{ record.ID }}
                </td>
                <td class="no-wrap">{{ record.Name + ' - ' + record.LongName }}</td>
                <td>
                  <span :class="`${isPrivacyMode ? 'blurred' : ''}`" v-html="record.Failure"></span>
                </td>
                <td>
                  <span :class="`${isPrivacyMode ? 'blurred' : ''}`" v-html="record.Address"></span>
                </td>
                <td class="no-wrap">{{ fd(record.CreatedOn) }}</td>
                <td class="no-wrap">{{ fd(record.UpdatedOn) }}</td>
                <td style="min-width: 200px">
                  <router-link class="btn btn-primary btn-sm btn-flat mr-8" tag="a"
                    :to="{ name: 'coin-health-detail', params: { id: record.AltCoinID } }">
                    Balance Sheet
                  </router-link>
                  <router-link class="btn btn-primary btn-sm btn-flat" tag="a"
                    :to="{ name: 'xchange-offline-pool', query: { coin: record.Name, id: record.AltCoinID } }">
                    Offline Pool
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="transactionFailureCauses && transactionFailureCauses.records.length" class="footer">
          <pagination :meta="transactionFailureCauses.meta" @pageChange="updatePageTF"></pagination>
        </div>
      </div>
    </div>
    <!-- Update Notes Modal -->
    <div id="bsModalUpdateNotes" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
      data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="closeUpdateNotesModal()">
      <div class="modal-dialog" role="document" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" type="button" @click="closeUpdateNotesModal()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 id="bsModalLabel" class="modal-title">Update Notes</h4>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmitUpdateNotes)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="notes" rules="required" tag="div">
                  <label for="notes">Notes</label>
                  <textarea id="notes" v-model="notes" class="form-control" rows="3"></textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="text-right">
                  <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                    <span v-if="isLoading" class="mr-4"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                    <span>Save</span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import { dateDiffInDays, formatDate, logout } from "@/lib/utils";
import findIndex from "lodash/findIndex";

export default {
  name: "TransactionFailureCauses",
  components: {
    Pagination,
  },
  data() {
    return {
      keywordFailure: "",
      transactionFailureCauses: null,
      privacyMode: false,
      isLoading: false,
      timer: null,
      notes: "",
      record: null,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "isPrivacyMode",
      "currentUser",
    ]),
  },
  methods: {
    fd(val) {
      return formatDate(val);
    },
    updatePageTF(page) {
      this.getTransactionFailureCauses(page);
    },
    searchTransactionFailureCauses(e) {
      if (e.key !== "/" && e.key !== "Control") {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getTransactionFailureCauses(1);
        }, 500);
      }
    },
    getTransactionFailureCauses(page = 1) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=TransactionFailure`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keywordFailure,
          limit: 8,
        },
      }).then(response => {
        this.transactionFailureCauses = response.data.data;
      }).catch(error => {
        if (error.data.message.toLowerCase() !== "unauthorized") {
          this.$toast.fire("", error.data.message, "error");
        }
      });
    },
    isNotificationNew(date) {
      return dateDiffInDays(new Date(date), new Date(), false) <= 2;
    },
    toggleSticky(record) {
      this.isLoading = true;
      const index = findIndex(this.transactionFailureCauses.records, { ID: record.ID });
      const payload = {
        table: "Transaction_Failure",
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.ID,
        field: "Sticky",
        value: record.Sticky ? 0 : 1,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=UpdateTable`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          if (index !== -1) {
            record = {
              ...record,
              Sticky: payload.value,
            };
            this.transactionFailureCauses.records.splice(index, 1, record);
          }
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    closeUpdateNotesModal() {
      $("#bsModalUpdateNotes").modal("hide");
    },
    updateNotes(record) {
      this.record = record;
      this.notes = this.record.Notes || "";
      $("#bsModalUpdateNotes").modal("show");
    },
    onSubmitUpdateNotes() {
      this.isLoading = true;
      const index = findIndex(this.transactionFailureCauses.records, { ID: this.record.ID });
      const payload = {
        table: "Transaction_Failure",
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: this.record.ID,
        field: "Notes",
        value: this.notes,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=UpdateTable`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          if (index !== -1) {
            this.record = {
              ...this.record,
              Notes: payload.value,
            };
            this.transactionFailureCauses.records.splice(index, 1, this.record);
          }
          this.notes = "";
          this.record = null;
          this.closeUpdateNotesModal();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
  },
  mounted() {
    this.privacyMode = this.isPrivacyMode;
    this.getTransactionFailureCauses();
    $(document).on("keydown", (e) => {
      if (e.key === "F2") {
        this.getTransactionFailureCauses();
        return "";
      }
    });
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes blink {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }
}

.dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.red {
  background: #DD0000;
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
  animation: blink 2s infinite;
}

.yellow {
  background: #fbbf24;

}

.gray {
  background: #d1d5db;
}

.gap-2 {
  gap: 4px;
}

.text-yellow {
  color: #fbbf24 !important;
}

.text-gray {
  color: #9ca3af !important;
}
</style>
