<template>
  <div aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       id="bsModalLogin" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">Login</h4>
        </div>
        <div class="modal-body">
          <p class="login-box-msg">Sign in to start your session</p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider class="form-group" name="username" rules="required" tag="div" v-slot="{ errors }">
                <input aria-label="Username" class="form-control"
                       placeholder="Username" type="text" v-model="form.User">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="form-group" name="password" rules="required" tag="div" v-slot="{ errors }">
                <input aria-label="Password" autocomplete="off"
                       class="form-control" placeholder="Password" type="password" v-model="form.Password">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="form-group" name="2 factor authentication" rules="required" tag="div"
                                  v-slot="{ errors }">
                <input aria-label="2 factor authentication" autocomplete="off"
                       class="form-control" placeholder="2 factor authentication" type="text" v-model="form.totp">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="form-group" rules="reCaptcha" tag="div" v-slot="{ errors }">
                <vue-recaptcha :sitekey="siteKey" @reset="reset" @verify="verify"
                               ref="reCaptcha" v-model="form.reCaptcha"></vue-recaptcha>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="row">
                <div class="col-xs-offset-8 col-xs-4">
                  <button :disabled="isLoading" class="btn btn-success btn-block btn-flat" type="submit">
                    <span v-if="!isLoading">Login</span>
                    <span v-if="isLoading">
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { ACCOUNT_INFO, SAVE_JWT_TOKEN, SAVE_MERCHANT_PERMISSIONS, PERSIST_AUTH } from "@/store/keys";
import { saveNotification } from "@/lib/utils";

export default {
  name: "LoginModal",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_reCAPTCHA_SITE_KEY,
      form: {
        User: "",
        Password: "",
        totp: "000000",
        reCaptcha: "",
        output: "json",
      },
      isLoading: false,
    };
  },
  methods: {
    verify(response) {
      this.form.reCaptcha = response;
    },
    reset() {
      this.form.reCaptcha = "";
    },
    onSubmit() {
      this.isLoading = true;
      let payload = this.form;
      payload = Object.assign(payload, { "g-recaptcha-response": payload.reCaptcha });
      delete payload.reCaptcha;
      this.login(payload);
    },
    login(payload) {
      let apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/Login`, {
        params: payload,
      }).then(response => {
        if (response.data === "Session already exists") {
          this.$toast.fire("", response.data, "error");
          this.isLoading = false;
        } else {
          // Commit user to store
          const user = response.data;
          delete user.Status;
          user.Username = payload.User.toLowerCase();
          // Set cookie
          this.$cookies.set("JSESSIONID", user.SessionID, "7d", "/", ".cointopay.com", true, "None");
          this.$store.dispatch(PERSIST_AUTH, user).then(() => {
            // Get account info
            this.$store.dispatch(ACCOUNT_INFO).then(response => {
              // Get JWT token
              apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
              this.$http.post(`${apiBaseUrl}/?Call=GenerateJWTToken`, this.qs.stringify({
                MerchantID: user.ID,
                APIKey: user.APIKey,
              }), {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }).then(response => {
                this.isLoading = false;
                this.$refs.reCaptcha.reset();
                // Save JWT token
                this.$store.commit(SAVE_JWT_TOKEN, response.data.data.Token);
                // Save merchant permissions
                this.$store.commit(SAVE_MERCHANT_PERMISSIONS, response.data.data.Permissions);
                this.close();
                this.$toast.fire("", "Session refresh successfully", "success");
                saveNotification("Session refresh successfully");
              }).catch(error => {
                this.isLoading = false;
                this.$refs.reCaptcha.reset();
                this.$toast.fire("", error.response.data.message, "error");
                saveNotification(error.response.data.message);
              });
            }).catch(error => {
              this.isLoading = false;
              this.$refs.reCaptcha.reset();
              this.$toast.fire("", error.response, "error");
              saveNotification(error.response);
            });
          });
        }
      }).catch(error => {
        this.isLoading = false;
        this.$refs.reCaptcha.reset();
        if (typeof error === "object") {
          this.$toast.fire("", error.data, "error");
          saveNotification(error.data);
        }
      });
    },
    close() {
      $("#bsModalLogin").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("login-modal", (args) => {
      $("#bsModalLogin").modal("show");
    });
  },
};
</script>

<style scoped></style>
