import {
  ACCOUNT_INFO,
  EXCHANGE_LIST,
  LIST_COUNTRIES,
  LIST_LOCALES,
  LIST_MERCHANTS,
  LOGOUT,
  PERMISSIONS,
  PERSIST_AUTH,
  RESET,
  ROLES,
  SAVE_ACCOUNT,
  SAVE_ACCOUNT_INFO,
  SAVE_COUNTRIES,
  SAVE_EXCHANGE_LIST,
  SAVE_LOCALES,
  SAVE_MERCHANTS,
  SAVE_PERMISSIONS,
  SAVE_ROLES,
  SAVE_TRANSFERWISE_CONFIG,
  SAVE_USER,
  TRANSFERWISE_CONFIG,
} from "./keys";

export default {
  [PERSIST_AUTH]({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit(SAVE_USER, user);
      resolve();
    });
  },
  [ACCOUNT_INFO]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      const user = getters.currentUser;
      const params = {
        Call: "Accountdetail",
        MerchantID: user.ID,
        APIKey: user.APIKey,
        output: "json",
      };
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this._vm.$http
        .get(`${apiBaseUrl}/v2REAPI`, {
          params: params,
        })
        .then(response => {
          if (response.status === 200) {
            commit(SAVE_ACCOUNT_INFO, response.data.data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [SAVE_ACCOUNT]({ getters }, payload) {
    const encodeForm = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
        )
        .join("&");
    };
    return new Promise((resolve, reject) => {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this._vm.$http
        .post(`${apiBaseUrl}/Register`, encodeForm(payload), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [PERMISSIONS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      const apiBaseUrl = process.env.VUE_APP_AUTHORIZATION_API_URL;
      this._vm.$http
        .get(`${apiBaseUrl}/?Call=ListPermissions`, {
          headers: {
            Authorization: "Bearer " + getters.jwtToken,
          },
        })
        .then(response => {
          if (response.status === 200) {
            commit(SAVE_PERMISSIONS, response.data.data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ROLES]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this._vm.$http
        .get(`${apiBaseUrl}/?Call=ListRoles`, {
          headers: {
            Authorization: "Bearer " + getters.jwtToken,
          },
        })
        .then(response => {
          if (response.status === 200) {
            commit(SAVE_ROLES, response.data.data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [EXCHANGE_LIST]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this._vm.$http
        .get(`${apiBaseUrl}/?Call=ListXChanges`, {
          headers: {
            Authorization: "Bearer " + getters.jwtToken,
          },
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data.filter(c => {
              return c.Name.toLowerCase() !== "livecoin";
            });
            commit(SAVE_EXCHANGE_LIST, data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [LIST_LOCALES]({ commit, getters }) {
    const apiBaseUrl = process.env.VUE_APP_API_URL;
    this._vm.$http
      .get(`${apiBaseUrl}/V2Processor?List`)
      .then(response => {
        const data = response.data;
        const locales = [];
        Object.keys(data).forEach(key => {
          if (typeof data[key] === "object") {
            locales.push({
              key: key,
              value: data[key].naam,
            });
          }
        });
        commit(SAVE_LOCALES, locales);
      })
      .catch(error => {
        console.log(error);
      });
  },
  [TRANSFERWISE_CONFIG]({ commit }) {
    const baseUrl = window.location.protocol + "//" + window.location.host;
    const configFile = baseUrl + "/config.json";
    this._vm.$http
      .get(`${configFile}`)
      .then(response => {
        commit(SAVE_TRANSFERWISE_CONFIG, response.data);
      })
      .catch(response => {});
  },
  [LIST_COUNTRIES]({ commit }) {
    const baseUrl = window.location.protocol + "//" + window.location.host;
    const countriesFile = baseUrl + "/countries.json";
    this._vm.$http
      .get(`${countriesFile}`)
      .then(response => {
        commit(SAVE_COUNTRIES, response.data);
      })
      .catch(response => {});
  },
  [LIST_MERCHANTS]({ commit, getters }, payload) {
    const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
    this._vm.$http
      .get(`${apiBaseUrl}/?Call=ListMerchants`, {
        headers: {
          Authorization: "Bearer " + getters.jwtToken,
        },
        params: payload,
      })
      .then(response => {
        if (response.status === 200) {
          commit(SAVE_MERCHANTS, response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  [LOGOUT]({ commit }) {
    return new Promise((resolve, reject) => {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this._vm.$http
        .get(`${apiBaseUrl}/Login`, {
          params: {
            logout: true,
            output: "json",
          },
        })
        .then(response => {
          // Reset state
          commit(RESET);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
