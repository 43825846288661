import store from "../store";
import router from "../router";
import * as _ from "lodash";
import { LOGOUT, SAVE_NOTIFICATIONS } from "@/store/keys";
import Swal from "sweetalert2";
import EventBus from "./eventBus";

const moment = require("moment-timezone/builds/moment-timezone-with-data-1970-2030");

require("../lib/eventBus");

moment.tz.setDefault("Europe/Amsterdam");

export function formatStatus(status, maxWidth = "") {
  let formattedStatus = "";
  const width = maxWidth
    ? "width:" + maxWidth + ";max-width:" + maxWidth
    : "width: 120px;max-width: 120px;";
  switch (status) {
    case true:
    case "vtoken-claimed":
    case "ltr-closed":
    case "payout completed":
    case "paid":
    case "reflextrader":
    case "Yes":
    case "swap":
      formattedStatus = `<label class="label label-success mb-0 d-block pointer" style="${width}px">${status}</label>`;
      break;
    case false:
    case "ltr-open":
    case "payout pending":
    case "underpaid":
    case "vtoken-active":
    case "No":
      formattedStatus = `<label class="label label-warning mb-0 d-block pointer" style="${width}px">${status}</label>`;
      break;
    case "waiting":
      formattedStatus = `<label class="label label-default mb-0 d-block pointer" style="${width}px">${status}</label>`;
      break;
    case "ltr-expired":
    case "expired":
    case "payout cancelled":
      formattedStatus = `<label class="label label-danger mb-0 d-block pointer" style="${width}px">${status}</label>`;
      break;
  }
  return formattedStatus;
}

export function formatDate(val, format = "YYYY-MM-DD hh:mm:ss A") {
  if (!val || val === "" || typeof val === "undefined") {
    return "-";
  }
  if (val.toString().indexOf("-") !== -1) {
    val = val.toString().replace(/-/g, "/");
  }
  const date = moment.utc(val, "YYYY-MM-DD HH:mm:ss").tz("UTC");
  return date.format(format);
}

export function dateDiffInDays(d1, d2, floor = true) {
  const diffTime = d2.getTime() - d1.getTime();
  if (floor) {
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  } else {
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
}

export function dateDiffInHours(d) {
  const duration = moment.duration(moment().diff(moment(d)));
  return Math.ceil(duration.asHours());
}

export function diffInHuman(d) {
  const duration = moment.duration(moment().diff(moment(d)));
  let minutes = Math.ceil(duration.asMinutes());
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes - days * 1440) / 60);
  minutes = Math.floor(minutes % 60);
  let diffInHuman = "";
  if (days > 0) {
    diffInHuman = days + (days > 1 ? " days" : "day") + " ";
  }
  if (hours > 0) {
    diffInHuman += hours + (hours > 1 ? " hours" : " hour") + " ";
  }
  if (minutes > 0) {
    diffInHuman += minutes + (minutes > 0 ? " minutes" : " minute");
  }
  return diffInHuman;
}

export function toFixed(num, dec = 8, rounding = "floor") {
  const calcDec = Math.pow(10, dec);
  const number =
    rounding === "floor" ? num * calcDec : Math.round(num * calcDec);
  return toDecimal(Math.trunc(number) / calcDec, dec);
}

export function toDecimal(num, dec) {
  if (num === 0) {
    return num.toFixed(dec);
  }
  const nSign = Math.sign(num);
  // remove the sign
  num = Math.abs(num);
  // if the number is in scientific notation remove it
  if (/\d+\.?\d*e[+-]*\d+/i.test(num)) {
    const zero = "0";
    const parts = String(num)
      .toLowerCase()
      .split("e"); // split into coeff and exponent
    const e = parts.pop(); // store the exponential part
    let l = Math.abs(e); // get the number of zeros
    const sign = e / l;
    const coeffArray = parts[0].split(".");
    if (sign === -1) {
      l = l - coeffArray[0].length;
      if (l < 0) {
        num =
          coeffArray[0].slice(0, l) +
          "." +
          coeffArray[0].slice(l) +
          (coeffArray.length === 2 ? coeffArray[1] : "");
      } else {
        num = zero + "." + new Array(l + 1).join(zero) + coeffArray.join("");
      }
    } else {
      const dec = coeffArray[1];
      if (dec) {
        l = l - dec.length;
      }
      if (l < 0) {
        num = coeffArray[0] + dec.slice(0, l) + "." + dec.slice(l);
      } else {
        num = coeffArray.join("") + new Array(l + 1).join(zero);
      }
    }
  } else {
    return nSign < 0 ? "-" + num.toFixed(dec) : num.toFixed(dec);
  }
  return nSign < 0 ? "-" + num : num;
}

export function isAuthorized(permission) {
  const permissions = store.getters.merchantPermissions;
  if (permission) {
    return _.findIndex(permissions, { Slug: permission }) !== -1;
  }
  return true;
}

export const payoutSchemas = [
  {
    title: "Keep with Cointopay",
    slug: "Keep",
  },
  {
    title: "Pay out EUR Bank",
    slug: "EUR Bank",
  },
  {
    title: "Pay out USD Bank",
    slug: "USD Bank",
  },
  {
    title: "Pay out GBP Bank",
    slug: "GBP Bank",
  },
  {
    title: "Wallet Cointopay",
    slug: "Keep",
  },
  {
    title: "Payout Paypal",
    slug: "PayPal",
  },
  {
    title: "Payout Payeer",
    slug: "Payeer",
  },
  {
    title: "Pay out to bank address book default",
    slug: "Bank address book",
  },
  {
    title: "Pay out to crypto address book default",
    slug: "Crypto address book",
  },
];

export function getPayoutSchemaType(payoutSchema) {
  if (payoutSchema === "Keep with Cointopay") {
    return "";
  }
  const banks = ["Pay out EUR Bank", "Pay out USD Bank", "Pay out GBP Bank"];
  if (banks.indexOf(payoutSchema) !== -1) {
    return "bank";
  } else if (payoutSchema === "Payout Payeer") {
    return "payeer";
  } else if (payoutSchema === "Payout Paypal") {
    return "paypal";
  } else if (payoutSchema === "Pay out to bank address book default") {
    return "address-book-bank";
  } else if (payoutSchema === "Pay out to crypto address book default") {
    return "address-book-crypto";
  }
}

export function logout(isLogout = false) {
  let functionLock = false;
  if (!functionLock) {
    functionLock = true;
    if (isLogout) {
      // Show wait dialog
      Swal.fire({
        title: "",
        text: "Logging out...",
        showConfirmButton: false,
        backdrop: true,
      });
      store
        .dispatch(LOGOUT)
        .then(response => {
          if (response.toLowerCase() === "success") {
            // Close all modals
            $(".modal").modal("hide");
            Swal.close();
            if (!window.location.pathname.endsWith("login")) {
              // Redirect to login screen
              return router.push({ name: "login" });
            }
          }
        })
        .catch(error => {
          Swal.close();
          console.log(error);
        });
    } else {
      EventBus.$emit("login-modal");
    }
    setTimeout(() => {
      functionLock = false;
    }, 5000);
  }
}

export function formatMerchantStatus(status) {
  const formattedStatus = status ? "Verified" : "Unverified";
  const lblClass = status ? "label-success" : "label-danger";
  return `<label class="label ${lblClass}">${formattedStatus}</label>`;
}

export function formatDataList(data) {
  data = data.map(record => {
    return formatData(record);
  });
  return data;
}

export function formatData(record) {
  const payoutSchema = getPayoutSchemaType(record.PayoutSchema);
  let addressBookBankPayout = "NA";
  let addressBookCryptoPayout = "NA";
  if (payoutSchema === "address-book-bank") {
    if (record.AddressBook && record.AddressBook !== "NA") {
      const addressBookBank = JSON.parse(record.AddressBook).transferwise;
      let address = null;
      Object.keys(addressBookBank).forEach(currency => {
        address = _.find(addressBookBank[currency], { default: true });
        if (address) {
          addressBookBankPayout = formatAddress(addressBookBankPayout, address);
        }
      });
    }
  } else if (payoutSchema === "address-book-crypto") {
    if (record.AddressBook && record.AddressBook !== "NA") {
      const addressBookCrypto = JSON.parse(record.AddressBook).crypto;
      let address = null;
      Object.keys(addressBookCrypto).forEach(currency => {
        address = _.find(addressBookCrypto[currency], { default: true });
        if (address) {
          addressBookCryptoPayout = address;
          return true;
        }
      });
    }
  }
  return {
    ...record,
    AddressBookBank: addressBookBankPayout,
    AddressBookCrypto: addressBookCryptoPayout,
  };
}

export function formatAddress(bankAddress, address) {
  bankAddress = bankAddress === "NA" ? {} : bankAddress;
  if (typeof address === "object") {
    Object.keys(address).forEach(i => {
      if (typeof address[i] === "string") {
        Object.assign(bankAddress, { [i]: address[i] });
      } else if (typeof address[i] === "object") {
        formatAddress(bankAddress, address[i]);
      }
    });
  }
  return bankAddress;
}

export function getOptions(key) {
  let options = [];
  switch (key) {
    case "Online":
      options = [
        {
          id: 0,
          label: "Offline",
        },
        {
          id: 1,
          label: "Online",
        },
      ];
      break;
    case "IsActive":
    case "Descope":
    case "FiatBridge":
    case "IsInputCurrency":
    case "VirtualCurrency":
    case "TradeLowRiskHighSpread":
    case "GetInfo":
    case "AvailableForAutonomy":
    case "Interest":
    case "ExcludeFromList":
    case "IsInUse":
    case "OfflinePoolChecks":
    case "OfflinePoolPreferred":
    case "Reopenable":
      options = [
        {
          id: 0,
          label: "No",
        },
        {
          id: 1,
          label: "Yes",
        },
      ];
      break;
    case "LTR":
    case "ERC20":
    case "BlockExplorerMode":
      options = [
        {
          id: 0,
          label: "Off",
        },
        {
          id: 1,
          label: "On",
        },
      ];
      break;
    case "XChangeNameID":
      options = store.getters.exchangeList.map(record => {
        return {
          id: record.ID,
          label: record.Name,
        };
      });
      break;
    case "Buy":
      options = [
        {
          id: 0,
          label: "Disabled",
        },
        {
          id: 1,
          label: "Enabled",
        },
      ];
      break;
    case "DisableDeposit":
    case "DisablePayout":
    case "DisableTrading":
    case "DisableTradingEngine":
    case "DisableBankPayout":
      options = [
        {
          id: 0,
          label: "Enabled",
        },
        {
          id: 1,
          label: "Disabled",
        },
      ];
      break;
    case "SecureCloud":
      options = [
        {
          id: 1,
          label: "SecureCloud",
        },
        {
          id: 2,
          label: "Fiat",
        },
        {
          id: 3,
          label: "XChange",
        },
      ];
      break;
    case "vFiat":
      options = [
        {
          id: 0,
          label: "crypto",
        },
        {
          id: 1,
          label: "virtual fiat",
        },
        {
          id: 2,
          label: "virtual shares",
        },
        {
          id: 3,
          label: "virtual commodities",
        },
        {
          id: 4,
          label: "virtual indices",
        },
        {
          id: 5,
          label: "virtual bond",
        },
      ];
      break;
    case "Priority":
      options = [
        {
          id: 1,
          label: "Critical",
        },
        {
          id: 2,
          label: "Significant",
        },
        {
          id: 3,
          label: "Moderate",
        },
        {
          id: 4,
          label: "Elevated",
        },
        {
          id: 5,
          label: "Default",
        },
      ];
      break;
  }
  return options;
}

export function formatSize(size) {
  return size;
}

export function generateIconUrl(name) {
  const iconName = name.toLowerCase().replace("_disabled", "");
  return `https://s3-eu-west-1.amazonaws.com/cointopay/img/${iconName}_dash2.png`;
}

export function generatePayload(payload) {
  let autonomy = payload.Autonomy;
  if (typeof autonomy === "boolean") {
    autonomy = autonomy ? 1 : 0;
  }
  const isBusiness = payload.AccountType.toLowerCase() === "business" ? 1 : 0;
  let formattedObj = {
    ID: payload.ID,
    shoptitle: payload.ShopTitle,
    ownernickname: payload.OwnerNickName,
    email: payload.Email,
    apikey: payload.APIKey,
    autonomy: autonomy,
    chargingmethod: payload.ChargingMethod,
    transactionconfirmurl: payload.TransactionConfirmURL,
    transactionfailurl: payload.TransactionFailURL,
    ECWIDID: payload.ECWID,
    ShopifyAPIKey: payload.ShopifyAPIKey,
    ShopifyPassword: payload.ShopifyPassword,
    ShopifyURL: payload.ShopifyURL,
    PreferredCoinID: payload.PreferredCoinID,
    SWAPPreferredCoinID: payload.SWAPPreferredCoinID ? 1 : 0,
    SelectedAltcoins: payload.SelectedAltCoins,
    AltCoinFiatPayOutExcludes: payload.AltCoinFiatPayOutExcludes,
    FlexibilityPercentage: payload.FlexibilityPercentage,
    payoutschema: payload.PayoutSchema,
    payoutto: payload.PayoutTo,
    PayoutIBAN: payload.PayoutIBAN,
    PayoutBIC: payload.PayoutBIC,
    BusinessAccount: payload.BusinessAccount
      ? payload.BusinessAccount
      : isBusiness,
    PayoutBankName: payload.PayoutBankName,
    PayoutBankLegalEntityIdentifier: payload.PayoutBankLegalEntityIdentifier,
    PayoutBankAddress: payload.PayoutBankAddress,
    PayoutBankCity: payload.PayoutBankCity,
    PayoutBankState: payload.PayoutBankState,
    PayoutBankCountry: payload.PayoutBankCountry,
    PayoutBankPostalCode: payload.PayoutBankPostalCode,
    PayoutFirstName: payload.PayoutFirstName,
    PayoutLastName: payload.PayoutLastName,
    PayoutFullName: payload.PayoutFullName,
    PayoutBirthDate: moment(new Date(payload.PayoutBirthDate)).format(
      "YYYY/MM/DD",
    ),
    PEP: payload.PEP,
    PayoutName: payload.PayoutName,
    PayoutAddress: payload.PayoutAddress,
    PayoutPostalCode: payload.PayoutPostalCode,
    PayoutCity: payload.PayoutCity,
    PayoutState: payload.PayoutState,
    PayoutCountry: payload.PayoutCountry,
    Emailnotifications: payload.EmailNotifications,
    PayoutCurrencyShortName: payload.PayoutCurrencyShortName,
    PayPalEnable:
      payload.PayPalEnable === false || payload.PayPalEnable === true
        ? payload.PayPalEnable
          ? 1
          : 0
        : payload.PayPalEnable,
    PayPalEmail: payload.PayPalEmail
      ? payload.PayPalEmail
      : "invalidmailaddress@cointopay.com",
    ExactPayout:
      payload.ExactPayout === false || payload.ExactPayout === true
        ? payload.ExactPayout
          ? 1
          : 0
        : payload.ExactPayout,
    BankEnable: payload.BankEnable,
    PayeerEnable: payload.PayeerEnable,
    "2FAEnabled": payload.Google2FAEnabled === "true" ? 1 : 0,
    TFA: payload.Google2FA,
    SocialOn: payload.SocialOn ? 1 : 0,
    SocialURL: payload.SocialURL,
    SocialFacebook: payload.SocialFacebook,
    SocialTwitter: payload.SocialTwitter,
    SocialLinkedIn: payload.SocialLinkedIn,
    SocialReddit: payload.SocialReddit,
    face: payload.Avatar,
    ProfileEdit: true,
    locale: payload.Locale,
    PayoutIdentityType: payload.PayoutIdentityType,
    PayoutIdentityCardNr: payload.PayoutIdentityCardNr,
    PayoutIdentityCountry: payload.PayoutIdentityCountry,
    PaymentOptions: payload.PaymentOptions,
    RiskProfileID: payload.RiskProfileID,
    DisableCheckOut: payload.DisableCheckOut,
    DisablePayout: payload.DisablePayout,
    LifetimeMember: payload.LifetimeMember,
    MaxLTR: payload.MaxLTR,
    ShowInternationalCC: payload.ShowInternationalCC,
    Referral: payload.Referral,
    EmailnotificationsCustomer: payload.EmailNotificationsCustomer,
    MasterTransactionFunction: payload.MasterTransactionFunction,
    V2: "",
    output: "json",
  };
  if (typeof payload.Password !== "undefined" && payload.Password !== "") {
    formattedObj = Object.assign(formattedObj, {
      ownerpassword: payload.Password,
    });
  }
  return formattedObj;
}

export function saveNotification(msg) {
  const notifications = store.getters.notifications;
  const notification = {
    message: msg,
    id: null,
    date: new Date(),
  };
  notifications.splice(0, 0, notification);
  store.commit(SAVE_NOTIFICATIONS, notifications);
}
