export default {
  isLoading: state => state.isLoading,
  isBtnLoading: state => state.isBtnLoading,
  isLoggedIn: state => state.isLoggedIn,
  currentUser: state => state.user,
  accountInfo: state => state.accountInfo,
  jwtToken: state => state.jwtToken,
  merchantPermissions: state => state.merchantPermissions,
  cartStatusList: state => state.cartStatusList,
  permissions: state => state.permissions,
  roles: state => state.roles,
  altCoins: state => state.altCoins,
  onlineAltCoins: state => state.altCoins.filter(coin => coin.isOnline),
  transactions: state => state.transactions,
  page: state => state.page,
  dashboardFilters: state => state.dashboardFilters,
  exchangeList: state => state.exchangeList,
  locales: state => state.locales,
  transferWiseConfig: state => state.transferWiseConfig,
  countries: state => state.countries,
  merchants: state => state.merchants,
  merchantsWithKeys: state => state.merchantsWithKeys,
  notifications: state => state.notifications,
  converter: state => state.converter,
  balanceSheetBuyEnabledOnlineData: state =>
    state.balanceSheetBuyEnabledOnlineData,
  balanceSheetBuyEnabledOfflineData: state =>
    state.balanceSheetBuyEnabledOfflineData,
  balanceSheetBuyDisabledOnlineData: state =>
    state.balanceSheetBuyDisabledOnlineData,
  balanceSheetBuyDisabledOfflineData: state =>
    state.balanceSheetBuyDisabledOfflineData,
  balanceSheetBuyBothOnlineData: state => state.balanceSheetBuyBothOnlineData,
  balanceSheetBuyBothOfflineData: state => state.balanceSheetBuyBothOfflineData,
  searchKeyword: state => state.searchKeyword,
  externalPaymentProviders: state => state.externalPaymentProviders,
  payoutTypes: state => state.payoutTypes,
  isExpanded: state => state.isExpanded,
  isPrivacyMode: state => state.isPrivacyMode,
  symbols: state => state.symbols,
  tickers: state => state.tickers,
  types: state => state.types,
};
