<template>
  <div id="bsModalUpdateTransactionLogs" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <form ref="observer" @submit.prevent="onSubmit">
            <div class="form-group" v-if="transaction">
              <label for="payment-provider">Type</label>
              <div class="flex align-items-center justify-between">
                <div class="checkbox mr-8">
                  <label class="flex align-items-center">
                    <input type="checkbox" v-model="isFiat" :checked="isFiat" />Fiat
                  </label>
                </div>
                <select id="payment-provider" class="form-control" v-model="paymentProvider">
                  <template v-if="transaction.Prefix === 'T'">
                    <option v-for="(pp, index) in filteredExternalPaymentProviders" :value="pp.ID" :key="index">
                      {{ pp.Name }}
                    </option>
                  </template>
                  <template v-if="transaction.Prefix === 'P'">
                    <option v-for="(pp, index) in filteredPayoutTypes" :value="pp.ID" :key="index">{{ pp.Name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <va-tabs>
              <div id="addLogs" slot="content" :data-title="'Add Logs'" class="active">
                <div class="form-group">
                  <label for="logs">Logs</label>
                  <textarea id="logs" v-model="form.logs" class="form-control" rows="4" ref="logs"></textarea>
                  <span id="log-error" class="text-danger"></span>
                </div>
                <div class="flex align-items-center">
                  <p class="mb-0 pointer mr-8" @click="updateLogs('Wrong label')">Wrong label</p>
                  <p class="mb-0 pointer mr-8" @click="updateLogs('Missing label')">Missing label</p>
                  <p class="mb-0 pointer mr-8" @click="updateLogs('Refund')">Refund</p>
                  <p class="mb-0 pointer" @click="updateLogs('NA')">NA</p>
                </div>
                <hr style="margin: 1rem 0;" />
                <div class="flex flex-wrap gap-2 align-items-center">
                  <p class="label label-primary mb-0 pointer no-wrap fast-selection" v-for="s in fastSelection"
                    style="padding: 0.75rem 1rem; font-size: 100%;" @click="updateSelection(s)" :key="s.ID">
                    {{ s.Name }}
                  </p>
                </div>
              </div>
              <div id="logsHistory" slot="content" :data-title="'Logs History'">
                <div v-if="transaction && transaction.Comments">
                  <div class="textarea" v-html="transaction.Comments"></div>
                </div>
                <div v-else>NA</div>
              </div>
            </va-tabs>
            <div class="text-right mt-8">
              <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                Update
                <span v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";
import VaTabs from "../VATabs";

export default {
  name: "UpdateTransactionLogsModal",
  components: { VaTabs },
  data() {
    return {
      isLoading: false,
      title: "",
      transaction: null,
      paymentProvider: null,
      form: {
        logs: "",
      },
      isFiat: false,
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "externalPaymentProviders",
      "payoutTypes",
    ]),
    fastSelection() {
      const options = [
        "ABN", "KNAB CHBO", "Finom", "N26", "ING CHBO", "ABNAMRO CHBO",
      ];
      const providers = this.transaction && this.transaction.Prefix === "T"
        ? this.externalPaymentProviders : this.payoutTypes;

      return providers.filter(p => {
        return this._.includes(p.Name.toLowerCase(), "sumup") || options.filter(o => p.Name.includes(o)).length;
      });
    },
    sortedExternalPaymentProviders() {
      return this._.sortBy(this.externalPaymentProviders, [p => p.Name.toLowerCase()], ["asc"]);
    },
    filteredExternalPaymentProviders() {
      return this.sortedExternalPaymentProviders.filter(record => {
        if (this.isFiat) {
          return record.isFiat === true;
        }
        return true;
      });
    },
    sortedPayoutTypes() {
      return this._.sortBy(this.payoutTypes, [p => p.Name.toLowerCase()], ["asc"]);
    },
    filteredPayoutTypes() {
      return this.sortedPayoutTypes.filter(record => {
        if (this.isFiat) {
          return record.isFiat === true;
        }
        return true;
      });
    },
  },
  watch: {
    "form.logs"(val) {
      if (val) {
        document.getElementById("log-error").innerText = "";
      }
    },
  },
  methods: {
    confirm(logs, paymentProviderID) {
      this.confirmHook(logs, paymentProviderID);
      this.close();
    },
    updateLogs(val) {
      if (this.form.logs !== "") {
        this.form.logs += " " + val;
      } else {
        this.form.logs = val + " ";
      }
      document.getElementById("logs").focus();
    },
    updateSelection(s) {
      this.isFiat = s.isFiat;
      this.paymentProvider = s.ID;
    },
    onSubmit() {
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let payload = {
        Call: "PaymentProvider",
        MerchantID: this.transaction.MerchantID,
        APIKey: "_",
        ID: this.transaction.TransactionID,
        Payout: this.transaction.Prefix === "T" ? 0 : 1,
        ProviderID: this.paymentProvider,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        const encodeForm = data => {
          return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
        };
        payload = {
          Call: "KYCLOG",
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          output: "json",
        };
        let logs = this.tz().format("DD/MM/YYYY HH:mm:ss") + " " + this.currentUser.Username + ": " + this.form.logs;
        if (this.transaction.Comments) {
          logs += "\n" + this.transaction.Comments;
        }
        if (this.transaction.Prefix === "T") {
          payload = Object.assign({}, payload, {
            transactionlog: logs,
            TransactionID: this.transaction.TransactionID,
          });
        } else {
          payload = Object.assign({}, payload, {
            payoutlog: logs,
            PayoutID: this.transaction.TransactionID,
          });
        }
        this.$http.post(`${apiBaseUrl}/v2REAPI`, encodeForm(payload), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }).then(response => {
          this.isLoading = false;
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", "Logs updated successfully", "success");
              saveNotification("Logs updated successfully");
              const comments = this.transaction.Prefix === "T" ? payload.transactionlog : payload.payoutlog;
              this.confirm(comments, this.paymentProvider);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          console.log(error);
          this.isLoading = false;
        });
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      this.transaction = null;
      this.paymentProvider = null;
      this.isFiat = true;
      this.form.logs = "";
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalUpdateTransactionLogs").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("update-transaction-logs-modal", (args) => {
      this.title = args.title;
      this.transaction = args.data;
      this.isFiat = true;
      const data = (this.transaction.Prefix === "T") ? this.sortedExternalPaymentProviders : this.sortedPayoutTypes;
      const type = this.transaction.ExternalTransactionProvider ? this.transaction.ExternalTransactionProvider : this.transaction.TransactionType;
      const pp = this._.find(data, { Name: type });
      if (pp) {
        this.paymentProvider = pp.ID;
        this.isFiat = pp.isFiat;
      }
      this.confirmHook = args.confirm;
      $("[href=\"#addLogs\"]").tab("show");
      $("#bsModalUpdateTransactionLogs").modal("show");
      setTimeout(() => {
        document.getElementById("logs").focus();
      }, 500);
    });
  },
};
</script>

<style scoped lang="scss">
.textarea {
  white-space: pre-wrap;
}

.fast-selection {
  color: #3c8dbc;

  &:hover {
    text-decoration: underline;
  }
}

.gap-2 {
  gap: 4px;
}
</style>
