<template>
  <div v-if="this.meta.total > 0" class="flex justify-between pagination">
    <div class="flex gap-1">
      <a :class="{ 'disabled': !isPrevious }" class="btn btn-default first" @click="first()">First</a>
      <a :class="{ 'disabled': !isPrevious }" class="btn btn-default prev" @click="paginate(false)">Previous</a>
    </div>
    <span class="info">{{ range }}</span>
    <div class="flex gap-1">
      <a :class="{ 'disabled': !isNext }" class="btn btn-default next" @click="paginate(true)">Next</a>
      <a :class="{ 'disabled': !isNext }" class="btn btn-default last" @click="last()">Last</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    meta: {
      required: true,
      default: {
        total: 0,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
        page: 1,
      },
    },
  },
  data() {
    return {
      isPrevious: false,
      isNext: false,
      totalPages: 0,
      range: "",
      length: 0,
    };
  },
  watch: {
    page(value) {
      this.extractData();
    },
    meta(value) {
      this.extractData();
    },
  },
  methods: {
    extractData() {
      if (this.meta.total > 0) {
        this.totalPages = Math.ceil(this.meta.total / this.meta.limit);
        this.toggle();
        this.range = this.generateRange();
      }
    },
    paginate(isNext = true) {
      if (isNext) {
        if (this.meta.page < this.totalPages) {
          const page = Number.parseInt(this.meta.page, 10) + 1;
          this.$emit("pageChange", page);
        }
      } else {
        if (this.meta.page > 1) {
          const page = Number.parseInt(this.meta.page) - 1;
          this.$emit("pageChange", page);
        }
      }
    },
    first() {
      this.$emit("pageChange", 1);
    },
    last() {
      this.$emit("pageChange", this.totalPages);
    },
    toggle() {
      this.isNext = this.meta.page < this.totalPages;
      this.isPrevious = this.meta.page > 1;
    },
    generateRange() {
      let end = this.meta.page * this.meta.limit;
      if (end > this.meta.total) {
        end = this.meta.total;
      }
      return `Showing ${this.meta.offset + 1} - ${end} of ${this.meta.total}`;
    },
  },
  created() {
    this.extractData();
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  padding: 1rem !important;
  margin: 0;
}

a {
  &.link {
    width: 80px;
    cursor: pointer;
    display: inline-block;
    font-weight: 600 !important;
    color: #BBC1C6;

    &:hover:not(.disabled) {
      color: #7C858E;
    }

    &.first,
    &.prev {
      text-align: left;
    }

    &.last,
    &.next {
      text-align: right;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

span {
  &.info {
    cursor: pointer;
    display: inline-block;
    font-weight: 600 !important;
    color: #BBC1C6;
  }
}

.flex {
  display: flex;
}

.gap-1 {
  gap: 8px;
}
</style>
