export default {
  isLoading: false,
  isBtnLoading: false,
  user: {},
  accountInfo: {},
  jwtToken: null,
  merchantPermissions: [],
  isLoggedIn: false,
  cartStatusList: [],
  permissions: [],
  roles: [],
  altCoins: [],
  transactions: [],
  page: 1,
  dashboardFilters: {
    range: null,
    merchantId: "",
    statuses: [],
    keyword: "",
  },
  exchangeList: [],
  locales: [],
  transferWiseConfig: null,
  countries: [],
  merchants: [],
  merchantsWithKeys: [],
  symbols: [],
  notifications: [],
  converter: {
    source: 1,
    sourceAltCoinId: 1,
    target: 0,
    targetAltCoinId: 625,
  },
  balanceSheetBuyEnabledOnlineData: [],
  balanceSheetBuyDisabledOnlineData: [],
  balanceSheetBuyEnabledOfflineData: [],
  balanceSheetBuyDisabledOfflineData: [],
  balanceSheetBuyBothOnlineData: [],
  balanceSheetBuyBothOfflineData: [],
  searchKeyword: "",
  externalPaymentProviders: [],
  payoutTypes: [],
  isExpanded: false,
  isPrivacyMode: false,
  tickers: [],
  types: [],
};
