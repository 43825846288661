import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import defaultState from "./state";
import localforage from "localforage";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  strictMode: true,
  asyncStorage: true,
  storage: localforage,
  restoreState: (key) => localforage.getItem(key),
});

export default new Vuex.Store({
  state: defaultState,
  plugins: [vuexLocal.plugin],
  getters,
  mutations: Object.assign(mutations, {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  }),
  actions,
  modules: {},
});
