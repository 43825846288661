<template>
  <router-link v-if="router && router.name && isAuthorized" :to="{ name: router.name, query: query }" tag="li">
    <a href="javascript:void(0)">
      <i :class="icon"></i>
      <span>{{ name }}</span>
      <span v-show="badge" class="pull-right-container">
        <small :class="[badge.type==='String'?'bg-green':'label-primary']"
               class="label pull-right">{{ badge.data }}</small>
      </span>
      <i v-if="name === 'Dashboard'" class="fa fa-refresh icon-refresh ml-8"></i>
    </a>
  </router-link>
  <li v-else-if="link && isAuthorized">
    <a :href="link" target="_blank">
      <i :class="icon"></i>
      <span>{{ name }}</span>
    </a>
  </li>
  <li v-else-if="isAuthorized" :class="getType">
    <div class="flex align-items-center justify-between">
      {{ isHeader ? name : '' }}
      <span v-if="isHeader" class="pointer" @click="toggleSideBarMenus()">
        <i v-if="!isExpanded" class="fa fa-plus-square"></i>
        <i v-else class="fa fa-minus-square"></i>
      </span>
    </div>
    <a v-if="!isHeader" href="javascript:void(0)">
      <i :class="icon"></i>
      <span>{{ name }}</span>
      <span class="pull-right-container">
        <small v-if="badge && badge.data" :class="[badge.type==='String'?'bg-green':'label-primary']"
               class="label pull-right">{{ badge.data }}</small>
        <i v-else class="fa fa-angle-left pull-right"></i>
      </span>
    </a>
    <ul v-if="items.length > 0" class="treeview-menu" :style="{display: isExpanded ? 'block' : 'none'}">
      <template v-for="(item,index) in items">
        <router-link v-if="item.router && item.router.name" :key="index" :data="item" :to="item.router" tag="li">
          <a>
            <i :class="item.icon"></i> {{ item.name }}
          </a>
        </router-link>
        <li :key="index" v-else>
          <template v-if="item.link">
            <a :href="item.link" target="_blank">
              <i :class="item.icon"></i> {{ item.name }}
            </a>
          </template>
          <template v-else>
            <a>
              <i :class="item.icon"></i> {{ item.name }}
            </a>
          </template>
        </li>
      </template>
    </ul>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_IS_EXPANDED } from "../store/keys";

export default {
  name: "va-slide-item",
  props: {
    type: {
      type: String,
      default: "item",
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    badge: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    router: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
    query: {
      type: Object,
      default() {
        return {};
      },
    },
    link: {
      type: String,
      default: "",
    },
    permission: {
      type: String,
      default: "",
    },
  },
  created() {
  },
  computed: {
    ...mapGetters([
      "merchantPermissions",
      "isExpanded",
    ]),
    getType() {
      if (this.isHeader) {
        return "header";
      }
      return this.type === "item" ? "" : "treeview" + (this.isExpanded ? " menu-open" : "");
    },
    isAuthorized() {
      if (this.permission) {
        return this._.findIndex(this.merchantPermissions, { Slug: this.permission }) !== -1;
      }
      return true;
    },
  },
  methods: {
    toggleSideBarMenus() {
      this.$store.commit(SAVE_IS_EXPANDED, !this.isExpanded);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-mini {
  &.sidebar-collapse {
    .main-sidebar {
      .icon-refresh {
        display: none;
      }
    }
  }
}
</style>
