<template>
  <div id="bsModalUpdateTransactionStatus" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div class="form-group">
                <label for="transactionID">Transaction ID</label>
                <input id="transactionID" v-model="form.TransactionID" class="form-control" readonly type="text" />
              </div>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="status" rules="required" tag="div">
                <label for="status">Transaction Status</label>
                <select id="status" v-model="form.statusID" class="form-control">
                  <option v-for="(status, index) in statusList" :key="index" :value="status.id">{{ status.label }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit" id="btnUpdateStatus">
                  <span v-if="!isLoading">Update Status</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "UpdateTransactionStatusModal",
  data() {
    return {
      isLoading: false,
      title: "",
      transaction: null,
      statusList: [
        {
          id: -1,
          label: "Expired",
        },
        {
          id: 0,
          label: "Waiting",
        },
        {
          id: 1,
          label: "Paid",
        },
        {
          id: 4,
          label: "Not Enough",
        },
      ],
      form: {
        TransactionID: "",
        targetMerchant: "",
        statusID: "",
        notenough: 0,
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    getStatusID(status) {
      const item = this._.find(this.statusList, (s) => {
        return s.label.toLowerCase() === status;
      });
      return item ? item.id : 0;
    },
    onSubmit() {
      this.isLoading = true;
      const url = process.env.VUE_APP_API_URL;
      const payload = { ...this.form };
      if (payload.statusID === 4) {
        payload.statusID = 1;
        payload.notenough = 1;
      } else {
        payload.notenough = 0;
      }
      this.$http.get(`${url}/v2REAPI`, {
        params: {
          Call: "TransactionStatusUpdate",
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          ...payload,
          output: "json",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", "Transaction status update requested successfully", "success");
              saveNotification("Transaction status update requested successfully");
              // Close modal
              this.confirm();
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
        this.$toast.fire("", error.data, "error");
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      this.form = {
        TransactionID: "",
        targetMerchant: "",
        statusID: "",
        notenough: 0,
      };
      $("#bsModalUpdateTransactionStatus").off();
      $("#bsModalUpdateTransactionStatus").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("update-transaction-status-modal", (args) => {
      this.title = args.title;
      this.transaction = args.data;
      if (this.transaction) {
        let statusID = this.getStatusID(this.transaction.Status);
        if (this.transaction.NotEnough === 1 && statusID === 1) {
          statusID = 4;
        }
        if (statusID === -1 || statusID === 0 || statusID === 4) {
          statusID = 1;
        } else if (statusID === 1) {
          statusID = 0;
        }
        this.form = {
          TransactionID: this.transaction.TransactionID,
          targetMerchant: this.transaction.MerchantID,
          statusID: statusID,
          notenough: this.transaction.NotEnough,
        };
      }
      this.confirmHook = args.confirm;
      $("#bsModalUpdateTransactionStatus").modal("show");
      $("#bsModalUpdateTransactionStatus").on("keypress", (evt) => {
        if (evt.key === "Enter") {
          this.onSubmit();
        }
      });
    });
  },
  destroyed() {
    $("#bsModalUpdateTransactionStatus").off();
  },
};
</script>

<style scoped></style>
