<template>
  <div id="bsModalRevolutBankResult" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <template v-if="data.length > 0">
            <div v-for="(record, index) in data" :key="index">
              <hr v-if="index > 0"/>
              <div class="row">
                <div class="col-md-3">ID:</div>
                <div class="col-md-9">
                  {{ record.id }}
                  <button class="btn btn-primary btn-flat btn-sm ml-8"
                          @click="verifyTransaction(record.id)">Verify
                  </button>
                  <span class="ml-8" v-if="isVerified">{{ isVerified }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Reference:</div>
                <div class="col-md-9">{{ record.reference }}</div>
              </div>
              <div v-for="(leg, i) in record.legs" :key="i">
                <div class="row">
                  <div class="col-md-3">Amount:</div>
                  <div class="col-md-9">{{ leg.amount + ' ' + leg.currency }}</div>
                </div>
                <div class="row">
                  <div class="col-md-3">Description:</div>
                  <div class="col-md-9">{{ leg.description }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">State:</div>
                <div class="col-md-9">{{ record.state }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">Type:</div>
                <div class="col-md-9">{{ record.type }}</div>
              </div>
              <div class="row">
                <div class="col-md-3">Updated At:</div>
                <div class="col-md-9">{{ record.updated_at }}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="mb-0">No record found!</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RevolutBankResultModal",
  data() {
    return {
      isLoading: false,
      title: "",
      data: [],
      isVerified: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    verifyTransaction(id) {
      const url = "https://ebrighter.com:9443/invoice-payment/?call=verifyTransaction&revolutTransactionCode=" + id;
      this.$http.get(`${url}`).then(response => {
        if (response.data.data.replace("Return Code: ", "") === "200") {
          this.isVerified = "Verified";
        } else {
          this.isVerified = "Not Verified";
        }
      }).catch(error => {
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      this.isVerified = null;
      $("#bsModalRevolutBankResult").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("revolut-bank-result-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      $("#bsModalRevolutBankResult").modal("show");
    });
  },
};
</script>

<style scoped></style>
