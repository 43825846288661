import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
// Views
import AuthLayout from "./views/AuthLayout.vue";
import Login from "./views/Login.vue";

import AdminLayout from "./views/AdminLayout.vue";
import Dashboard from "./views/Dashboard.vue";
import * as _ from "lodash";

Vue.use(VueRouter);
const routes = [
  {
    path: "/admin",
    component: AdminLayout,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
          requiresAuth: true,
        },
      },
      {
        path: "charts",
        name: "charts",
        component: () =>
          import(/* webpackChunkName: "charts" */ "./views/Charts.vue"),
        meta: {
          title: "Charts",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "transactions/:id",
        name: "transaction-detail",
        component: () =>
          import(
            /* webpackChunkName: "transaction-detail" */ "./views/TransactionDetail.vue"
          ),
        meta: {
          title: "Transaction Detail",
          requiresAuth: true,
          permission: "view-transactions",
        },
      },
      {
        path: "kyc/:id?",
        name: "kyc",
        component: () =>
          import(/* webpackChunkName: "kyc" */ "./views/KYC.vue"),
        meta: {
          title: "KYC",
          requiresAuth: true,
          permission: "kyc-commander",
        },
      },
      {
        path: "payouts/:id?",
        name: "payouts",
        component: () =>
          import(/* webpackChunkName: "payouts" */ "./views/Payouts.vue"),
        meta: {
          title: "Payouts",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "balance-sheet",
        name: "balance-sheet",
        component: () =>
          import(
            /* webpackChunkName: "balance-sheet" */ "./views/BalanceSheet.vue"
          ),
        meta: {
          title: "Balance Sheet",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "coin-health",
        name: "coin-health",
        component: () =>
          import(
            /* webpackChunkName: "coin-health" */ "./views/CoinHealth.vue"
          ),
        meta: {
          title: "Coin Health",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "coin-health/:id",
        name: "coin-health-detail",
        component: () =>
          import(
            /* webpackChunkName: "coin-health-detail" */ "./views/CoinHealthDetail.vue"
          ),
        meta: {
          title: "Coin Health Detail",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "users/:id",
        name: "user-detail",
        component: () =>
          import(
            /* webpackChunkName: "merchant-detail" */ "./views/MerchantDetail.vue"
          ),
        meta: {
          title: "User Detail",
          requiresAuth: true,
          permission: "view-users",
        },
      },
      {
        path: "reports",
        name: "reports",
        component: () =>
          import(/* webpackChunkName: "reports" */ "./views/Reports.vue"),
        meta: {
          title: "Reports",
          requiresAuth: true,
          permission: "view-transactions",
        },
      },
      {
        path: "users",
        name: "users",
        component: () =>
          import(/* webpackChunkName: "users" */ "./views/Users.vue"),
        meta: {
          title: "Users",
          requiresAuth: true,
          permission: "view-users",
        },
      },
      {
        path: "marketing",
        name: "marketing",
        component: () =>
          import(/* webpackChunkName: "marketing" */ "./views/Marketing.vue"),
        meta: {
          title: "Marketing",
          requiresAuth: true,
          permission: "marketing-commander",
        },
      },
      {
        path: "news",
        name: "news",
        component: () =>
          import(/* webpackChunkName: "news" */ "./views/News.vue"),
        meta: {
          title: "News",
          requiresAuth: true,
          permission: "marketing-commander",
        },
      },
      {
        path: "market/pos/files",
        name: "market-pos-files",
        component: () =>
          import(
            /* webpackChunkName: "market-pos-files" */ "./views/MarketPosFiles.vue"
          ),
        meta: {
          title: "Market POS Files",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "market/files",
        name: "market-files",
        component: () =>
          import(
            /* webpackChunkName: "market-files" */ "./views/MarketFiles.vue"
          ),
        meta: {
          title: "Market Files",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "analytics",
        name: "analytics",
        component: () =>
          import(/* webpackChunkName: "analytics" */ "./views/Analytics.vue"),
        meta: {
          title: "Analytics",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "transactions/all/:name?",
        name: "transactions",
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "./views/Transactions.vue"
          ),
        meta: {
          title: "Transactions",
          requiresAuth: true,
          permission: "view-transactions",
        },
      },
      {
        path: "roles",
        name: "roles",
        component: () =>
          import(/* webpackChunkName: "roles" */ "./views/Roles.vue"),
        meta: {
          title: "Roles",
          requiresAuth: true,
          permission: "view-roles",
        },
      },
      {
        path: "vtokens",
        name: "vtokens",
        component: () =>
          import(/* webpackChunkName: "vtokens" */ "./views/VTokens.vue"),
        meta: {
          title: "VTokens",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "xchange-search",
        name: "xchange-search",
        component: () =>
          import(
            /* webpackChunkName: "xchange-search" */ "./views/XChangeSearch.vue"
          ),
        meta: {
          title: "XChange Search",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "platform-scheduling",
        name: "platform-scheduling",
        component: () =>
          import(
            /* webpackChunkName: "platform-scheduling" */ "./views/PlatformScheduling.vue"
          ),
        meta: {
          title: "Platform Scheduling",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "platform-scheduling-sql",
        name: "platform-scheduling-sql",
        component: () =>
          import(
            /* webpackChunkName: "platform-scheduling-sql" */ "./views/PlatformSchedulingSQL.vue"
          ),
        meta: {
          title: "Platform Scheduling SQL",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "transaction-scheduling",
        name: "transaction-scheduling",
        component: () =>
          import(
            /* webpackChunkName: "transaction-scheduling" */ "./views/TransactionScheduling.vue"
          ),
        meta: {
          title: "Transaction Scheduling",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "platform-licensing",
        name: "platform-licensing",
        component: () =>
          import(
            /* webpackChunkName: "platform-licensing" */ "./views/PlatformLicensing.vue"
          ),
        meta: {
          title: "Platform Licensing",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "transaction-xrp-pool",
        name: "transaction-xrp-pool",
        component: () =>
          import(
            /* webpackChunkName: "transaction-xrp-pool" */ "./views/TransactionXRPPool.vue"
          ),
        meta: {
          title: "Transaction XRP Pool",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "transaction-ether-pool",
        name: "transaction-ether-pool",
        component: () =>
          import(
            /* webpackChunkName: "transaction-ether-pool" */ "./views/TransactionEtherPool.vue"
          ),
        meta: {
          title: "Transaction Ether Pool",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "transaction-scheduling-tasks/:merchantID?",
        name: "transaction-scheduling-tasks",
        component: () =>
          import(
            /* webpackChunkName: "transaction-scheduling-tasks" */ "./views/TransactionSchedulingTasks.vue"
          ),
        meta: {
          title: "Transaction Scheduling Tasks",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "merchant-autonomy",
        name: "merchant-autonomy",
        component: () =>
          import(
            /* webpackChunkName: "merchant-autonomy" */ "./views/MerchantAutonomy.vue"
          ),
        meta: {
          title: "Merchant Autonomy",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "audit",
        name: "audit",
        component: () =>
          import(/* webpackChunkName: "audit" */ "./views/Audit.vue"),
        meta: {
          title: "EPP Audit Login",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "trade-flex",
        name: "trade-flex",
        component: () =>
          import(/* webpackChunkName: "trade-flex" */ "./views/TradeFlex.vue"),
        meta: {
          title: "Trade Flex",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "xchange-offline-pool",
        name: "xchange-offline-pool",
        component: () =>
          import(
            /* webpackChunkName: "xchange-offline-pool" */ "./views/AltCoinXChangeOfflinePool.vue"
          ),
        meta: {
          title: "XChange Offline Pool",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "merchant-fees-custom",
        name: "merchant-fees-custom",
        component: () =>
          import(
            /* webpackChunkName: "merchant-fees-custom" */ "./views/MerchantFeesCustom.vue"
          ),
        meta: {
          title: "Merchant Fees Custom",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "merchant-cc",
        name: "merchant-cc",
        component: () =>
          import(
            /* webpackChunkName: "merchant-cc" */ "./views/MerchantCC.vue"
          ),
        meta: {
          title: "Merchant CC",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "config-merchants-cc/:id",
        name: "config-merchants-cc",
        component: () =>
          import(
            /* webpackChunkName: "config-merchants-cc" */ "./views/ConfigMerchantsCC.vue"
          ),
        meta: {
          title: "Reverse Merchant CC",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "config-merchants-bank/:id",
        name: "config-merchants-bank",
        component: () =>
          import(
            /* webpackChunkName: "config-merchants-bank" */ "./views/ConfigMerchantsBank.vue"
          ),
        meta: {
          title: "Reverse Merchant Bank",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "altcoin-stats",
        name: "altcoin-stats",
        component: () =>
          import(
            /* webpackChunkName: "altcoin-stats" */ "./views/AltCoinStats.vue"
          ),
        meta: {
          title: "AltCoin Stats",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "paypal-audit",
        name: "paypal-audit",
        component: () =>
          import(
            /* webpackChunkName: "paypal-audit" */ "./views/PayPalTransactions.vue"
          ),
        meta: {
          title: "PayPal Audit",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "trade-bots",
        name: "trade-bots",
        component: () =>
          import(/* webpackChunkName: "trade-bots" */ "./views/TradeBots.vue"),
        meta: {
          title: "Trade Bots",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "bot-detail/:id",
        name: "bot-detail",
        component: () =>
          import(/* webpackChunkName: "bot-detail" */ "./views/BotDetail.vue"),
        meta: {
          title: "Bot Detail",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "spawn-bot",
        name: "spawn-bot",
        component: () =>
          import(
            /* webpackChunkName: "merchant-trade-bot" */ "./views/MerchantTradeBot.vue"
          ),
        meta: {
          title: "Spawn Bot",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "trading-config",
        name: "trading-config",
        component: () =>
          import(
            /* webpackChunkName: "trading-config" */ "./views/TradingConfig.vue"
          ),
        meta: {
          title: "Artemis Trading Config",
          requiresAuth: true,
          permission: "admin-commander",
        },
      },
      {
        path: "not-authorized",
        name: "not-authorized",
        component: () =>
          import(
            /* webpackChunkName: "not-authorized" */ "./views/NotAuthorized.vue"
          ),
        meta: {
          title: "Not authorized",
          requiresAuth: true,
        },
      },
      {
        path: "",
        redirect: { name: "dashboard" },
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
        meta: {
          title: "Login",
          authLayout: true,
          requiresAuth: false,
        },
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "./views/ForgotPassword.vue"
          ),
        meta: {
          title: "Forgot Password",
          authLayout: true,
          requiresAuth: false,
        },
      },
      {
        path: "",
        redirect: "login",
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

// eslint-disable-next-line space-before-function-paren
const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};
router.beforeEach(waitForStorageToBeReady);

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // Change page title based on router meta title
  const isAuthLayout = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.authLayout);
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " | Cointopay Admin";
  }
  // Change body class based on layout
  const bodyElement = document.getElementsByTagName("body")[0];
  if (isAuthLayout) {
    bodyElement.classList.remove("skin-black", "sidebar-mini");
    bodyElement.classList.add("login-page");
  } else {
    bodyElement.classList.remove("login-page");
    bodyElement.classList.add("skin-black", "sidebar-mini");
  }
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]"),
  ).map(el => el.parentNode.removeChild(el));
  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map(tagDef => {
        const tag = document.createElement("meta");
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute("data-vue-router-controlled", "");
        return tag;
      })
      .forEach(tag => document.head.appendChild(tag));
  }
  // Auth middleware
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      return next({ name: "login" });
    }
  } else {
    if (store.getters.isLoggedIn) {
      return next({ name: "dashboard" });
    }
  }
  // Authorization middleware
  if (to.matched.some(record => record.meta.permission)) {
    const permission = _.find(store.getters.merchantPermissions, {
      Slug: to.meta.permission,
    });
    if (!permission) {
      return next({ name: "not-authorized" });
    }
  }
  next();
});

export default router;
