import {
  RESET,
  SAVE_ACCOUNT_INFO,
  SAVE_ALT_COINS,
  SAVE_BALANCE_SHEET_BUY_BOTH_OFFLINE,
  SAVE_BALANCE_SHEET_BUY_BOTH_ONLINE,
  SAVE_BALANCE_SHEET_BUY_DISABLED_OFFLINE,
  SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE,
  SAVE_BALANCE_SHEET_BUY_ENABLED_OFFLINE,
  SAVE_BALANCE_SHEET_BUY_ENABLED_ONLINE,
  SAVE_CONVERTER,
  SAVE_COUNTRIES,
  SAVE_DASHBOARD_FILTERS,
  SAVE_EXCHANGE_LIST,
  SAVE_IS_EXPANDED,
  SAVE_JWT_TOKEN,
  SAVE_LOCALES,
  SAVE_MERCHANT_PERMISSIONS,
  SAVE_MERCHANTS,
  SAVE_MERCHANTS_WITH_KEYS,
  SAVE_NOTIFICATIONS,
  SAVE_PAGE,
  SAVE_PERMISSIONS,
  SAVE_ROLES,
  SAVE_TRANSACTIONS,
  SAVE_TRANSFERWISE_CONFIG,
  SAVE_USER,
  SET_EXTERNAL_PAYMENT_PROVIDERS,
  SET_PAYOUT_TYPES,
  SET_SEARCH_KEYWORD,
  START_BTN_LOADING,
  START_LOADING,
  STOP_BTN_LOADING,
  STOP_LOADING,
  SET_IS_PRIVACY_MODE,
  SET_CART_STATUSES,
  SAVE_SYMBOLS,
  SAVE_TICKERS,
  SAVE_TYPES,
} from "./keys";

const defaultState = {
  isLoading: false,
  isBtnLoading: false,
  user: {},
  accountInfo: {},
  jwtToken: null,
  merchantPermissions: [],
  isLoggedIn: false,
  cartStatusList: [
    { id: 1, value: "Not Set" },
    { id: 2, value: "Processing" },
    { id: 3, value: "Processed" },
    { id: 4, value: "Shipped" },
    { id: 5, value: "Reversed" },
    { id: 6, value: "Refunded" },
    { id: 7, value: "Completed" },
    { id: 8, value: "Waiting" },
  ],
  transactions: [],
  page: 1,
  dashboardRange: null,
  locales: [],
  transferWiseConfig: null,
  merchants: [],
  isPrivacyMode: false,
};

export default {
  [START_LOADING](state) {
    state.isLoading = true;
  },
  [STOP_LOADING](state) {
    state.isLoading = false;
  },
  [START_BTN_LOADING](state) {
    state.isBtnLoading = true;
  },
  [STOP_BTN_LOADING](state) {
    state.isBtnLoading = false;
  },
  [SAVE_USER](state, payload) {
    state.isLoggedIn = true;
    state.user = payload;
  },
  [SAVE_ACCOUNT_INFO](state, payload) {
    state.accountInfo = payload;
  },
  [SAVE_PERMISSIONS](state, payload) {
    state.permissions = payload;
  },
  [SAVE_ROLES](state, payload) {
    state.roles = payload;
  },
  [SAVE_ALT_COINS](state, payload) {
    state.altCoins = payload;
  },
  [SAVE_JWT_TOKEN](state, payload) {
    state.jwtToken = payload;
  },
  [SAVE_TRANSACTIONS](state, payload) {
    state.transactions = payload;
  },
  [SAVE_PAGE](state, payload) {
    state.page = payload;
  },
  [SAVE_MERCHANT_PERMISSIONS](state, payload) {
    state.merchantPermissions = payload;
  },
  [SAVE_MERCHANTS_WITH_KEYS](state, payload) {
    state.merchantsWithKeys = payload;
  },
  [SAVE_DASHBOARD_FILTERS](state, payload) {
    state.dashboardFilters = payload;
  },
  [SAVE_EXCHANGE_LIST](state, payload) {
    state.exchangeList = payload;
  },
  [SAVE_LOCALES](state, payload) {
    state.locales = payload;
  },
  [SAVE_TRANSFERWISE_CONFIG](state, payload) {
    state.transferWiseConfig = payload;
  },
  [SAVE_COUNTRIES](state, payload) {
    state.countries = payload;
  },
  [SAVE_MERCHANTS](state, payload) {
    state.merchants = payload;
  },
  [SAVE_SYMBOLS](state, payload) {
    state.symbols = payload;
  },
  [SAVE_NOTIFICATIONS](state, payload) {
    state.notifications = payload;
  },
  [SAVE_CONVERTER](state, payload) {
    state.converter = payload;
  },
  [SAVE_BALANCE_SHEET_BUY_ENABLED_ONLINE](state, payload) {
    state.balanceSheetBuyEnabledOnlineData = payload;
  },
  [SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE](state, payload) {
    state.balanceSheetBuyDisabledOnlineData = payload;
  },
  [SAVE_BALANCE_SHEET_BUY_ENABLED_OFFLINE](state, payload) {
    state.balanceSheetBuyEnabledOfflineData = payload;
  },
  [SAVE_BALANCE_SHEET_BUY_DISABLED_OFFLINE](state, payload) {
    state.balanceSheetBuyDisabledOfflineData = payload;
  },
  [SAVE_BALANCE_SHEET_BUY_BOTH_ONLINE](state, payload) {
    state.balanceSheetBuyBothOnlineData = payload;
  },
  [SAVE_BALANCE_SHEET_BUY_BOTH_OFFLINE](state, payload) {
    state.balanceSheetBuyBothOfflineData = payload;
  },
  [SET_SEARCH_KEYWORD](state, payload) {
    state.searchKeyword = payload;
  },
  [SET_EXTERNAL_PAYMENT_PROVIDERS](state, payload) {
    state.externalPaymentProviders = payload;
  },
  [SET_PAYOUT_TYPES](state, payload) {
    state.payoutTypes = payload;
  },
  [SAVE_IS_EXPANDED](state, payload) {
    state.isExpanded = payload;
  },
  [SET_IS_PRIVACY_MODE](state, payload) {
    state.isPrivacyMode = payload;
  },
  [SET_CART_STATUSES](state, payload) {
    state.cartStatusList = payload;
  },
  [SAVE_TICKERS](state, payload) {
    state.tickers = payload;
  },
  [SAVE_TYPES](state, payload) {
    state.types = payload;
  },
  [RESET](state) {
    Object.keys(defaultState).forEach(key => {
      state[key] = defaultState[key];
    });
  },
};
