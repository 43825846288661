<template>
  <div class="content-wrapper" id="content-wrap">
    <section class="content">
      <transition mode="out-in" name="page">
        <div>
          <va-loader v-if="isLoading"></va-loader>
          <router-view></router-view>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VaLoader from "@/components/partials/Loader.vue";

export default {
  name: "va-content-wrap",
  components: { VaLoader },
  computed: {
    ...mapGetters([
      "isLoading",
    ]),
  },
  created() {
  },
};
</script>

<style>
.page-enter-active, .page-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.page-enter, .page-leave-to {
  opacity: 0;
}
</style>
