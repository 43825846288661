<template>
  <div @click="clickOverlay" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       id="bsModal" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <div v-if="modalContent" v-html="modalContent"></div>
        </div>
        <div class="modal-footer" v-if="modalFooter">
          <button @click="cancel" class="btn btn-default btn-flat" type="button" v-if="cancelText">{{ cancelText }}</button>
          <button @click="confirm" class="btn btn-primary btn-flat" type="button" v-if="confirmText">{{ confirmText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Modal",
  mounted() {
    this.$bus.$on("modal-open", (args) => {
      this.type = args.type;
      this.title = args.title;
      this.modalContent = args.modalContent || "";
      this.data = args.data || null;
      this.template = args.template || "";
      this.confirmText = args.confirmText || "";
      this.cancelText = args.cancelText || "";
      this.confirmBeforeHook = args.confirmBefore;
      this.confirmAfterHook = args.confirmAfter;
      this.cancelBeforeHook = args.cancelBefore;
      this.cancelAfterHook = args.cancelAfter;
      this.clickOverlayHook = args.clickOverlay;
      $("#bsModal").modal("show");
    });
    $("#bsModal").on("show.bs.modal", (e) => {});
    $("#bsModal").on("hidden.bs.modal", (e) => {
      this.confirmAfterHook();
      this.cancelAfterHook();
      this.resetProperties();
    });
    $("#bsModal").on("overlay.bs.modal", (e) => {
      this.clickOverlayHook();
    });
  },
  data() {
    return {
      type: "",
      title: "",
      modalContent: "",
      data: null,
      modalFooter: false,
      template: "",
      confirmText: "",
      cancelText: "",
      confirmBeforeHook: () => {
      },
      confirmAfterHook: () => {
      },
      cancelBeforeHook: () => {
      },
      cancelAfterHook: () => {
      },
      clickOverlayHook: () => {
      },
    };
  },
  methods: {
    confirm: function () {
      this.confirmBeforeHook();
      this.close();
      this.cancelAfterHook = () => {
      };
    },
    cancel: function () {
      this.cancelBeforeHook();
      this.close();
      this.confirmAfterHook = () => {
      };
    },
    resetProperties: function () {
      this.type = "";
      this.title = "";
      this.data = null;
      this.modalContent = "";
      this.template = "";
      this.modalFooter = false;
      this.confirmText = "";
      this.cancelText = "";
      this.confirmBeforeHook = () => {
      };
      this.confirmAfterHook = () => {
      };
      this.cancelBeforeHook = () => {
      };
      this.cancelAfterHook = () => {
      };
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
      this.clickOverlayHook();
    },
    close() {
      $("#bsModal").modal("hide");
    },
  },
};
</script>

<style scoped></style>
