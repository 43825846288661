<template>
  <div>
    <va-navibar></va-navibar>
    <va-slider :slideMenuItems="slideMenuItems"></va-slider>
    <va-content-wrap></va-content-wrap>
    <Modal></Modal>
    <!-- Login Modal -->
    <login-modal></login-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VANaviBar from "@/components/partials/NaviBar.vue";
import VASlider from "@/components/partials/Slider.vue";
import VAContentWrap from "@/components/partials/ContentWrap.vue";
import Modal from "@/components/Modal.vue";
import slideMenuItems from "./../lib/slideMenuItems.js";
import { LIST_LOCALES, SAVE_ALT_COINS } from "@/store/keys";
import LoginModal from "../components/modals/LoginModal";

export default {
  name: "AdminLayout",
  data() {
    return {
      slideMenuItems: slideMenuItems,
    };
  },
  components: {
    LoginModal,
    "va-navibar": VANaviBar,
    "va-slider": VASlider,
    "va-content-wrap": VAContentWrap,
    Modal,
  },
  computed: {
    ...mapGetters([
      "jwtToken",
    ]),
  },
  methods: {
    getAltCoins() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListAltCoins`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.status === 200) {
          let data = response.data.data;
          data = this._.orderBy(data, ["Name", "asc"]);
          this.$store.commit(SAVE_ALT_COINS, data);
        }
      }).catch(() => {});
    },
    menu() {
      "use strict";

      const DataKey = "lte.tree";

      const Default = {
        animationSpeed: 500,
        accordion: true,
        followLink: false,
        trigger: ".treeview a",
      };

      const Selector = {
        tree: ".tree",
        treeview: ".treeview",
        treeviewMenu: ".treeview-menu",
        open: ".menu-open, .active",
        li: "li",
        data: "[data-widget=\"tree\"]",
        active: ".active",
      };

      const ClassName = {
        open: "menu-open",
        tree: "tree",
      };

      const Event = {
        collapsed: "collapsed.tree",
        expanded: "expanded.tree",
      };

      // Tree Class Definition
      // =====================
      const Tree = function (element, options) {
        this.element = element;
        this.options = options;
        $(this.element).addClass(ClassName.tree);
        $(Selector.treeview + Selector.active, this.element).addClass(ClassName.open);

        this._setUpListeners();
      };

      Tree.prototype.toggle = function (link, event) {
        const treeviewMenu = link.next(Selector.treeviewMenu);
        const parentLi = link.parent();
        const isOpen = parentLi.hasClass(ClassName.open);

        if (!parentLi.is(Selector.treeview)) {
          return;
        }

        if (!this.options.followLink || link.attr("href") === "#") {
          event.preventDefault();
        }

        if (isOpen) {
          this.collapse(treeviewMenu, parentLi);
        } else {
          this.expand(treeviewMenu, parentLi);
        }
      };

      Tree.prototype.expand = function (tree, parent) {
        const expandedEvent = $.Event(Event.expanded);

        if (this.options.accordion) {
          const openMenuLi = parent.siblings(Selector.open);
          const openTree = openMenuLi.children(Selector.treeviewMenu);
          this.collapse(openTree, openMenuLi);
        }

        parent.addClass(ClassName.open);
        tree.stop().slideDown(this.options.animationSpeed, function () {
          $(this.element).trigger(expandedEvent);
          parent.height("auto");
        }.bind(this));
      };

      Tree.prototype.collapse = function (tree, parentLi) {
        const collapsedEvent = $.Event(Event.collapsed);

        // tree.find(Selector.open).removeClass(ClassName.open);
        parentLi.removeClass(ClassName.open);
        tree.stop().slideUp(this.options.animationSpeed, function () {
          // tree.find(Selector.open + ' > ' + Selector.treeview).slideUp();
          $(this.element).trigger(collapsedEvent);

          // Collapse child items
          parentLi.find(Selector.treeview).removeClass(ClassName.open).find(Selector.treeviewMenu).hide();
        }.bind(this));
      };

      // Private

      Tree.prototype._setUpListeners = function () {
        const that = this;
        $(this.element).on("click", this.options.trigger, function (event) {
          that.toggle($(this), event);
        });
      };

      // Plugin Definition
      // =================
      function Plugin(option) {
        return this.each(function () {
          const $this = $(this);
          const data = $this.data(DataKey);
          if (!data) {
            const options = $.extend({}, Default, $this.data(), typeof option === "object" && option);
            $this.data(DataKey, new Tree($this, options));
          }
        });
      }

      const old = $.fn.tree;

      $.fn.tree = Plugin;
      $.fn.tree.Constructor = Tree;

      // No Conflict Mode
      // ================
      $.fn.tree.noConflict = function () {
        $.fn.tree = old;
        return this;
      };

      // Tree Data API
      // =============
      $(Selector.data).each(function () {
        Plugin.call($(this));
      });
    },
  },
  mounted() {
    this.getAltCoins();
    this.$store.dispatch(LIST_LOCALES);
    this.menu();
  },
};
</script>

<style scoped></style>
