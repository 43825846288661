import "bootstrap/dist/css/bootstrap.min.css";
import "admin-lte/dist/css/AdminLTE.min.css";
import "admin-lte/dist/css/skins/_all-skins.min.css";
import "font-awesome/css/font-awesome.min.css";
import "summernote/dist/summernote-lite.css";

import Vue from "vue";
import axios from "axios";
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import EventBus from "./lib/eventBus.js";
import Swal from "sweetalert2";
import VueCookies from "vue-cookies";
import vSelect from "vue-select";
import { VTooltip } from "v-tooltip";
import VueSilentbox from "vue-silentbox";
import ToggleButton from "vue-js-toggle-button";
import lodash from "lodash";
import "vue2-datepicker/index.css";
import * as moment from "moment-timezone";
import "bootstrap";
import "jquery-slimscroll";
import "admin-lte";
import { logout } from "./lib/utils";

const qs = require("qs");
const jquery = require("jquery");

Vue.config.productionTip = false;

global.$ = jquery;

Vue.prototype.$bus = EventBus;

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend("isYoutubeURL", {
  message: field => "Provide a valid youtube url",
  validate: value => {
    if (value === "") {
      return true;
    }
    const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return !!value.match(pattern);
  },
});

extend("reCaptcha", {
  message: field => "Verify you are not a robot",
  validate: value => {
    return value !== "";
  },
  computesRequired: true,
});

localize("en", en);

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
// Sweet alert 2
Vue.prototype.$swal = Swal;
const toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  showCancelButton: true,
  cancelButtonText: "",
  timer: 5000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
  customClass: {
    container: "toastr",
    cancelButton: "btn-cancel",
  },
});
Vue.prototype.$toast = toast;
Vue.use(VueCookies);
Vue.use(VueSilentbox);
Vue.use(ToggleButton);

Vue.directive("tooltip", VTooltip);
Vue.prototype._ = lodash;
const instance = axios.create({
  withCredentials: true,
});
// Response Interceptor
instance.interceptors.response.use(
  response => {
    if (
      response.data &&
      response.data.status_code &&
      response.data.status_code === 400 &&
      response.data.message &&
      response.data.message.toLowerCase() === "no permission"
    ) {
      logout(false);
    }
    return response;
  },
  error => {
    const currentRoute = window.location.pathname.split("/").filter(Boolean);
    const msg = error.response.data?.message || error.response.data;
    if (error.response && error.response.status) {
      if (
        error.response.status === 401 &&
        currentRoute[currentRoute.length - 1] !== "login"
      ) {
        logout(true);
      } else if (
        msg &&
        !typeof msg === Object &&
        msg.startsWith("JWT strings must contain exactly 2 period characters.")
      ) {
        logout(true);
      }
    }
    return Promise.reject(error.response);
  },
);
Vue.prototype.$http = instance;
Vue.prototype.qs = qs;

// Select 2
Vue.component("v-select", vSelect);
// Editor
Vue.component("editor", require("@/components/summernote").default);

moment.tz.setDefault("Europe/Amsterdam");
Vue.prototype.tz = moment;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
