<template>
  <aside class="main-sidebar" id="slider">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar" style="padding-bottom: 3rem;">
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul data-widget="tree" class="sidebar-menu">
        <va-slide-item :permission="item.permission" :badge="item.badge" :icon="item.icon" :isHeader="item.isHeader"
                       :items="item.items" :key="index" :link="item.link" :name="item.name"
                       :router="item.router" :query="item.query" :type="item.type" v-for="(item,index) in slideMenuItems">
        </va-slide-item>
      </ul>
    </section>
    <div class="version">Version: 1.0.1</div>
  </aside>
</template>

<script>
import VASlideItem from "@/components/VASlideItem.vue";

export default {
  name: "va-slider",
  components: {
    "va-slide-item": VASlideItem,
  },
  props: {
    slideMenuItems: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
};
</script>

<style scoped lang="scss">
  li {
    &.header {
      color: #b8c7ce !important;
    }
  }
  .version {
    color: #ffffff;
    padding: 0.5rem 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 230px;
    left: 0;
    background: #1b2428;
  }
</style>
