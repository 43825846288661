<template>
    <div class="login-box">
      <div class="login-logo">
        <img src="/assets/img/logo.svg" alt="Cointopay Admin"/>
      </div>
      <div class="login-box-body">
        <router-view/>
      </div>
    </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped></style>
