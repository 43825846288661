<template>
  <div id="bsModalRapydResult" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RapydResultModal",
  data() {
    return {
      isLoading: false,
      title: "",
      message: "",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      this.isVerified = null;
      $("#bsModalRapydResult").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("rapyd-result-modal", (args) => {
      this.title = args.title;
      this.message = args.message;
      $("#bsModalRapydResult").modal("show");
    });
  },
};
</script>

<style scoped></style>
