<template>
  <div :class="wrapBgColor" class="info-box text-black">
    <span class="info-box-icon" v-bind:class="bgColor">
      <i v-bind:class="bgIcon"></i>
    </span>
    <div class="info-box-content">
      <span class="info-box-text">{{ data ? data.title : '' }}</span>
      <template v-if="data && data.lines">
        <span class="info-box-number" v-for="(line, index) in data.lines" :key="index">
          <span v-if="line.text" :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ line.text }}</span>
          <span v-if="line.number" :class="`${isPrivacyMode ? 'blurred' : ''}`">{{ parseNumber(line) }}</span>
        </span>
      </template>

      <div v-if="isProgress" class="progress">
        <div :style="`width: ${percentage}%`" class="progress-bar"></div>
      </div>
      <span class="progress-description">
        {{ progressDescription }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "va-info-box",
  props: {
    wrapBgColor: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    bgIcon: {
      type: String,
    },
    data: {
      type: Object,
      default: null,
    },
    numberType: {
      type: String,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    progressDescription: {
      type: String,
      default: "",
    },
    isProgress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "isPrivacyMode",
    ]),
  },
  methods: {
    parseNumber(line) {
      let result = line.number;
      switch (line.numberType) {
        case "percentage":
          result += "%";
          break;
        case "comma":
          result = Number(result).toLocaleString("en");
          break;
        default:
          break;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
