<template>
  <div v-show="!isLoading" class="dashboard">
    <!-- Overview -->
    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <a class="text-black" @click="load24HData()">
          <va-info-box :data="{
            title: 'Transactions / 24H',
            lines: [
              {
                text: dashboardOverview24H.transactionsStats.totalPaidTransactions + ' / ' + dashboardOverview24H.transactionsStats.totalTransactions + ' Incoming'
              },
              {
                text: dashboardOverview24H.payoutsStats.totalPayouts + ' Payouts'
              }
            ]
          }" bgColor="bg-aqua" bgIcon="ion ion-cash"></va-info-box>
        </a>
      </div>
      <div class="col-lg-3 col-sm-6">
        <va-info-box :data="{
          title: 'Volume (EUR) / 24H',
          lines: [
            {
              text: toFixed(dashboardOverview24H.transactionsStats.totalPaidVolume, 2) + ' / ' + toFixed(dashboardOverview24H.transactionsStats.totalVolume, 2)
            },
            {
              text: Number(dashboardOverview24H.payoutsStats.totalPendingVolume) === Number(dashboardOverview24H.payoutsStats.totalVolume) ? toFixed(dashboardOverview24H.payoutsStats.totalVolume, 2) : toFixed(dashboardOverview24H.payoutsStats.totalPendingVolume, 2) + ' / ' + toFixed(dashboardOverview24H.payoutsStats.totalVolume, 2)
            }
          ]
        }" bgColor="bg-red" bgIcon="fa ion-stats-bars"></va-info-box>
      </div>
      <div class="col-lg-3 col-sm-6">
        <va-info-box :data="{
          title: 'Revenue (EUR) / 24H',
          lines: [
            {
              number: dashboardOverview24H.transactionsStats.totalRevenueInEUR
            },
            {
              number: toFixed(dashboardOverview24H.payoutsStats.totalRevenueInEUR, 2)
            }
          ]
        }" bgColor="bg-green" bgIcon="ion ion-social-euro"></va-info-box>
      </div>
      <div class="col-lg-3 col-sm-6">
        <router-link :to="{ name: 'kyc', hash: '#all' }">
          <va-info-box :data="{
            title: 'New Registrations / 24H',
            lines: [
              {
                number: dashboardOverview24H.TotalRegistrations
              }
            ]
          }" bgColor="bg-yellow" bgIcon="ion ion-ios-people"></va-info-box>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9">
        <!-- Last 24H Transactions -->
        <div class="box box-info">
          <div class="box-header">
            <div class="flex-col-sm flex flex-wrap align-items-center gap-8">
              <div v-if="sync !== ''" class="text-bold text-danger ml-8 flex align-items-center" v-html="this.sync">
              </div>
              <div class="flex align-items-center">
                <label class="mb-0 ml-8">From</label>
                <date-picker v-model="filters.range.startDate" :clearable="false" :editable="false" class="ml-8"
                  :disabled-date="disabledDates" format="YYYY-MM-DD" value-type="format"></date-picker>
              </div>
              <div class="flex align-items-center">
                <label class="mb-0 ml-8">To</label>
                <date-picker v-model="filters.range.endDate" :clearable="false" :editable="false" class="ml-8"
                  :disabled-date="disabledDates" format="YYYY-MM-DD" value-type="format"></date-picker>
              </div>
              <label v-if="transactionsList && transactionsList.length">
                <input type="checkbox" v-model="isClientSearch" />
                DataSet Search
              </label>
              <div v-if="!isClientSearch" class="flex-col-sm flex flex-wrap align-items-center gap-8">
                <v-select v-model="filters.merchantId" :options="merchants" class="ml-8" style="min-width: 200px"
                  :reduce="m => m.ID" label="Name" :clearable="false"></v-select>
                <div style="position: relative">
                  <input ref="search" v-model="filters.keyword" aria-label="Search" id="search"
                    class="form-control w-150 ml-8" style="padding-right: 16px;" placeholder="Search..."
                    type="search" />
                  <span style="position: absolute; right: 8px; top: 8px; cursor: pointer; color: #808080;"
                    v-if="filters.keyword !== ''" @click="filters.keyword = ''">
                    <i class="fa fa-close"></i>
                  </span>
                </div>
                <statuses-dropdown @statuses="updateFilters" :reset="resetStatuses" />
              </div>
              <div v-if="isClientSearch" class="flex-col-sm flex flex-wrap align-items-center gap-8">
                <v-select v-model="merchantId" :options="merchants" class="ml-8" style="min-width: 200px"
                  :reduce="m => m.ID" label="Name" :clearable="false"></v-select>
                <div style="position: relative">
                  <input ref="search" v-model="keyword" aria-label="Search" id="search" class="form-control w-150 ml-8"
                    style="padding-right: 16px;" placeholder="Search..." type="search" />
                  <span style="position: absolute; right: 8px; top: 8px; cursor: pointer; color: #808080;"
                    v-if="keyword !== ''" @click="keyword = ''">
                    <i class="fa fa-close"></i>
                  </span>
                </div>
                <statuses-local-dropdown @statuses="updateStatusFilters" />
                <types-dropdown @types="updateTypeFilters" />
              </div>
              <button class="btn btn-primary btn-sm btn-flat" @click="searchLatestInfo()">Search</button>
              <button class="btn btn-primary btn-sm btn-flat" @click="resetSearch()">Reset</button>
            </div>
          </div>
          <div class="box-body no-padding">
            <div v-if="filteredTransactions && filteredTransactions.length" class="footer">
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </div>
            <div class="table-responsive">
              <table class="table table-condensed table-striped mb-0">
                <thead>
                  <tr>
                    <th class="pointer" @click="sort('OwnerNickName')">
                      <span class="flex align-items-center justify-between">
                        Merchant
                        <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('TransactionID')">
                      <span class="flex align-items-center justify-between">
                        Transaction ID
                        <span v-if="sortKey === 'TransactionID'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="text-right pointer" @click="sort('OriginalAmount')">
                      <span class="flex align-items-center justify-between">
                        Original Amount
                        <span v-if="sortKey === 'OriginalAmount'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('Amount')">
                      <span class="flex align-items-center justify-between">
                        Amount
                        <span v-if="sortKey === 'Amount'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('ExternalTransactionProvider')">
                      <span class="flex align-items-center justify-between">
                        Type
                        <span v-if="sortKey === 'ExternalTransactionProvider'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('Status')">
                      <span class="flex align-items-center justify-between">
                        Status
                        <span v-if="sortKey === 'Status'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('CreatedOn')">
                      <span class="flex align-items-center justify-between">
                        Created On
                        <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                    <th class="pointer" @click="sort('Date')">
                      <span class="flex align-items-center justify-between">
                        Date
                        <span v-if="sortKey === 'Date'" class="ml-4">
                          <i class="fa fa-sort"></i>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="paginatedTransactions.length > 0">
                    <tr v-for="(transaction, index) in paginatedTransactions" :key="index"
                      :class="{ 'glow': transaction.isNew }">
                      <td class="no-wrap">
                        <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
                          <router-link :to="{ name: 'user-detail', params: { id: transaction.MerchantID } }" tag="a">
                            {{ transaction.OwnerNickName + ' - ' + transaction.ShopTitle }}
                          </router-link>
                        </span>
                      </td>
                      <td style="min-width:150px;">
                        <router-link
                          :to="{ name: 'transaction-detail', params: { id: transaction.Prefix + transaction.TransactionID.toString().padStart(9, '0') } }"
                          class="d-inline-block" tag="a" @click.native="savePage()">
                          {{ transaction.Prefix + transaction.TransactionID.toString().padStart(9, '0') }}
                        </router-link>
                        <span class="ml-8 pointer d-inline-block" @click="copyToClipboard(transaction.TransactionID)">
                          <i class="fa fa-clipboard"></i>
                        </span>
                        <span v-if="transaction.Prefix === 'T'" class="ml-8 pointer d-inline-block"
                          @click="copyToClipboard(transaction.Security, false)">
                          <i class="fa fa-key"></i>
                        </span>
                        <span v-if="transaction.Prefix === 'T'" class="ml-8 pointer d-inline-block"
                          @click="copyToClipboard(transaction.Security, true)">
                          <i class="fa fa-globe"></i>
                        </span>
                        <span v-if="transaction.Prefix === 'T'" class="ml-8 pointer d-inline-block"
                          @click="copyToClipboard(transaction.CustomerReferenceNr)">
                          <i class="fa fa-user"></i>
                        </span>
                        <a target="_blank" class="ml-8 pointer d-inline-block text-black"
                          v-if="transaction.CoinAddress !== 'NA'"
                          :href="transaction.BlockExplorer + transaction.CoinAddress">
                          <i class="fa fa-book"></i>
                        </a>
                      </td>
                      <td class="nowrap" align="right">
                        {{ transaction.OriginalAmount.toFixed(8) + ' ' + transaction.InputCurrency }}
                        <span class="ml-8">
                          <i class="fa fa-copy pointer"
                            @click="toConverter(toFixed(transaction.OriginalAmount.toFixed(8)), shortNameToID(transaction.InputCurrency))"></i>
                        </span>
                      </td>
                      <td class="nowrap" align="right">
                        {{ transaction.Amount.toFixed(8) + ' ' + transaction.LongName }}
                        <span class="ml-8">
                          <i class="fa fa-copy pointer"
                            @click="toConverter(transaction.Amount, transaction.AltCoinID)"></i>
                        </span>
                      </td>
                      <td class="nowrap">{{
                        transaction.ExternalTransactionProvider ? transaction.ExternalTransactionProvider :
                          transaction.TransactionType
                      }}
                      </td>
                      <td>
                        <span class="flex align-items-center">
                          <span v-if="transaction.Prefix !== 'P'" @click="launchTransactionStatusModal(transaction)"
                            v-html="fs(transaction.Status)" class="mr-8"></span>
                          <span v-if="transaction.Prefix === 'P'" v-html="fs(transaction.Status)" class="mr-8"></span>
                          <i class="fa fa-comments-o fa-2x pointer"
                            @click="launchUpdateTransactionLogsModal(transaction, index)"></i>
                          <a @click="getRevolutTransactionDetail(transaction.Security)" class="ml-8 text-black"
                            v-if="getType(transaction) === 'RevolutCard'">
                            <i class="fa fa-credit-card fa-2x"
                              :class="{ 'text-dark-gray': transaction.ExternalTransactionProvider !== 'RevolutCard' && (transaction.Status === 'waiting' || transaction.Status === 'expired') }"></i>
                          </a>
                          <a v-if="getType(transaction) === 'Bunq'" @click="getBunqTransactionDetail(transaction)"
                            class="ml-8">
                            <img style="height: 20px;" src="@/assets/bunq.svg" alt="" />
                          </a>
                          <a @click="getSquareTransactionDetail(transaction)" class="ml-8"
                            v-if="transaction.revolutID && getType(transaction) === 'Squareup'">
                            <img style="height: 20px;" src="@/assets/square.png" alt="" />
                          </a>
                          <a @click="getStripeTransactionDetail(transaction)" class="ml-8"
                            v-if="transaction.revolutID && getType(transaction) === 'Stripe'">
                            <i class="fa fa-cc-stripe fa-2x"></i>
                          </a>
                          <a @click="getRevolutBankTransactionDetail(transaction)" class="ml-8 text-black"
                            v-if="getType(transaction) === 'RevolutBank' && transaction.revolutID">
                            <i class="fa fa-bank"></i>
                          </a>
                          <a @click="getRapydTransactionDetail(transaction)" class="ml-8"
                            v-if="transaction.revolutID && getType(transaction) === 'Rapyd'">
                            <img style="height: 20px;" src="@/assets/rapyd.png" alt="" />
                          </a>
                          <a @click="getPayrexxTransactionDetail(transaction)" class="ml-8"
                            v-if="transaction.revolutID && getType(transaction) === 'Payrexx'">
                            <i class="fa fa-mug-hot"></i>
                          </a>
                        </span>
                      </td>
                      <td class="nowrap">{{ fd(transaction.CreatedOn) }}</td>
                      <td class="nowrap">{{ fd(transaction.Date) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="8">No transaction available</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-if="filteredTransactions && filteredTransactions.length" class="footer">
              <pagination :meta="meta" @pageChange="updatePage"></pagination>
            </div>
          </div>
        </div>
        <TransactionFailureCauses />
      </div>
      <div class="col-lg-3">
        <div class="box box-warning">
          <div class="box-header">
            <h3 class="box-title">Pending KYC For Review</h3>
          </div>
          <div class="box-body no-padding">
            <div class="table-responsive">
              <table class="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Merchant</th>
                    <th style="min-width: 160px;width: 160px">Last Updated On</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="pendingKYC.length > 0">
                    <tr v-for="(kyc, index) in pendingKYC" :key="index">
                      <td style="word-break: break-all;">
                        <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
                          <router-link :to="{ name: 'user-detail', params: { id: kyc.ID } }" tag="a">
                            {{ kyc.ShopTitle }}
                          </router-link>
                        </span>
                      </td>
                      <td>{{ fd(kyc.LastUpdatedOn) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="2">No pending KYC found</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box box-primary">
          <div class="box-header">
            <h3 class="box-title">New Registrations</h3>
          </div>
          <div class="box-body no-padding">
            <div class="table-responsive">
              <table class="table table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th style="min-width: 160px;width: 160px">Created On</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="recentRegistrations.length > 0">
                    <tr v-for="(registration, index) in recentRegistrations" :key="index">
                      <td class="no-wrap" style="word-break: break-all;">
                        <span :class="`${isPrivacyMode ? 'blurred' : ''}`">
                          <router-link :to="{ name: 'user-detail', params: { id: registration.ID } }" tag="a">
                            {{ registration.Name }}
                          </router-link>
                        </span>
                      </td>
                      <td class="no-wrap">{{ fd(registration.RegisteredOn) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="2">No registration found</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Sync Status -->
        <div class="box box-primary">
          <div class="box-header">
            <h3 class="box-title">Sync Status</h3>
          </div>
          <div class="box-body">
            <div style="display: flex; flex-wrap: wrap; gap: 4px;">
              <button @click="checkSyncStatus()" :class="`btn ${isInSync ? 'btn-success' : 'btn-danger'}`">
                <span class="fa fa-spinner fa-spin" v-if="isSyncStatusLoading"></span>
                {{ isInSync ? 'Synced' : 'Out of Sync' }}</button>
              <button @click="checkServletStatus('artemis')"
                :class="`btn ${statusCheck.artemis ? 'btn-success' : 'btn-danger'}`">
                <span class="fa fa-spinner fa-spin" v-if="statusCheckLoaders.artemis"></span>
                Artemis {{ statusCheck.artemis ? 'OK' : 'Not OK' }}</button>
              <button @click="checkServletStatus('kucoin')"
                :class="`btn ${statusCheck.kucoin ? 'btn-success' : 'btn-danger'}`">
                <span class="fa fa-spinner fa-spin" v-if="statusCheckLoaders.kucoin"></span>
                Kucoin {{ statusCheck.kucoin ? 'OK' : 'Not OK' }}</button>
              <button @click="checkServletStatus('kucoinSockets')"
                :class="`btn ${statusCheck.kucoinSockets ? 'btn-success' : 'btn-danger'}`">
                <span class="fa fa-spinner fa-spin" v-if="statusCheckLoaders.kucoinSockets"></span>
                Kucoin Sockets {{ statusCheck.kucoinSockets ? 'OK' : 'Not OK' }}</button>
              <button @click="checkServletStatus('kucoinBroker')"
                :class="`btn ${statusCheck.kucoinBroker ? 'btn-success' : 'btn-danger'}`">
                <span class="fa fa-spinner fa-spin" v-if="statusCheckLoaders.kucoinBroker"></span>
                Kucoin Broker {{ statusCheck.kucoinBroker ? 'OK' : 'Not OK' }}</button>
            </div>
          </div>
        </div>
        <!-- Privay Mode -->
        <div class="box box-primary">
          <div class="box-header">
            <h3 class="box-title">Privacy Mode</h3>
          </div>
          <div class="box-body">
            <label class="mr-4">Privacy Mode: </label>
            <toggle-button v-model="privacyMode"
              :color="{ checked: '#89B92E', unchecked: '#FF0000', disabled: '#CCCCCC' }"
              :labels="{ checked: 'On', unchecked: 'Off' }" :sync="true" :value="isPrivacyMode"
              @change="togglePrivacyMode()" />
          </div>
        </div>
      </div>
    </div>
    <!-- Update transaction status modal -->
    <update-transaction-status-modal></update-transaction-status-modal>
    <!-- Update transaction logs modal -->
    <update-transaction-logs-modal></update-transaction-logs-modal>
    <!-- Revolut Result Modal -->
    <revolut-result-modal></revolut-result-modal>
    <revolut-bank-result-modal></revolut-bank-result-modal>
    <!-- Bunq Result Modal -->
    <bunq-result-modal></bunq-result-modal>
    <!-- Square Result Modal -->
    <square-result-modal></square-result-modal>
    <!-- Stripe Result Modal -->
    <stripe-result-modal></stripe-result-modal>
    <!-- Rapyd Result Modal -->
    <rapyd-result-modal></rapyd-result-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VAInfoBox from "../widgets/VAInfoBox.vue";
import Pagination from "@/components/Pagination.vue";
import { dateDiffInDays, formatDate, formatStatus, saveNotification, toFixed, isAuthorized } from "@/lib/utils";
import {
  ROLES,
  SAVE_CONVERTER,
  SAVE_PAGE,
  SAVE_TRANSACTIONS,
  SET_EXTERNAL_PAYMENT_PROVIDERS,
  SET_PAYOUT_TYPES,
  STOP_LOADING,
  SET_IS_PRIVACY_MODE,
  SAVE_DASHBOARD_FILTERS,
} from "@/store/keys";
import DatePicker from "vue2-datepicker";
import UpdateTransactionStatusModal from "../components/modals/UpdateTransactionStatusModal.vue";
import UpdateTransactionLogsModal from "../components/modals/UpdateTransactionLogsModal";
import RevolutResultModal from "../components/modals/RevolutResultModal";
import BunqResultModal from "../components/modals/BunqResultModal";
import SquareResultModal from "../components/modals/SquareResultModal";
import StripeResultModal from "../components/modals/StripeResultModal";
import RevolutBankResultModal from "../components/modals/RevolutBankResultModal";
import RapydResultModal from "../components/modals/RapydResultModal";
import TransactionFailureCauses from "../components/dashboard/TransactionFailureCauses.vue";
import $ from "jquery";
import mixins from "@/lib/mixins";
import StatusesDropdown from "@/components/StatusesDropdown.vue";
import StatusesLocalDropdown from "@/components/StatusesLocalDropdown.vue";
import TypesDropdown from "@/components/TypesDropdown.vue";
import moment from "moment-timezone";
// eslint-disable-next-line import/no-webpack-loader-syntax
import SyncDashboard from "worker-loader!@/syncDashboard.js";

export default {
  name: "Dashboard",
  components: {
    StatusesDropdown,
    StatusesLocalDropdown,
    TypesDropdown,
    RapydResultModal,
    RevolutBankResultModal,
    StripeResultModal,
    SquareResultModal,
    BunqResultModal,
    RevolutResultModal,
    "va-info-box": VAInfoBox,
    Pagination,
    DatePicker,
    UpdateTransactionStatusModal,
    UpdateTransactionLogsModal,
    TransactionFailureCauses,
  },
  mixins: [mixins],
  data() {
    return {
      dashboardOverview24H: {
        transactionsStats: {
          totalTransactions: 0,
          totalPaidTransactions: 0,
          totalVolume: 0,
          totalPaidVolume: 0,
          totalRevenueInEUR: 0,
        },
        payoutsStats: {
          totalPayouts: 0,
          totalPendingVolume: 0,
          totalVolume: 0,
          totalRevenueInEUR: 0,
        },
        TotalRegistrations: 0,
      },
      sync: "",
      transactionsList: [],
      recentRegistrations: [],
      pendingKYC: [],
      currentPage: 1,
      limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      keyword: "",
      merchantId: "",
      statuses: [],
      types: [],
      reload: false,
      filters: {
        keyword: "",
        merchantId: "",
        statuses: [],
        range: {
          startDate: this.tz().subtract(6, "days").format("YYYY-MM-DD"),
          endDate: this.tz().format("YYYY-MM-DD"),
        },
      },
      sortKey: "CreatedOn",
      order: "desc",
      destroyed: false,
      privacyMode: this.isPrivacyMode,
      socketTransactions: null,
      isCloseSocketTransactions: false,
      worker: null,
      syncStatus: null,
      isSyncStatusLoading: false,
      resetStatuses: null,
      merchantsList: [],
      isClientSearch: false,
      serlvetsStatusTimer: null,
      statusCheck: {
        artemis: true,
        kucoin: true,
        kucoinSockets: true,
        kucoinBroker: true,
      },
      statusCheckLoaders: {
        artemis: false,
        kucoin: false,
        kucoinSockets: false,
        kucoinBroker: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "transactions",
      "page",
      "dashboardFilters",
      "converter",
      "searchKeyword",
      "externalPaymentProviders",
      "payoutTypes",
      "altCoins",
      "isPrivacyMode",
    ]),
    filteredTransactions() {
      if (!this.isClientSearch) {
        return this.transactionsList;
      }

      let keyword = this.keyword ? this.keyword.trim().toLowerCase() : "";

      let formattedTransactions = this.transactionsList.filter(transaction => {
        // Apply the keyword filtering
        if (keyword) {
          if (keyword.startsWith("xc")) {
            const xChangeIDKeyword = keyword.replace("xc", "");
            if (transaction.XChangeID !== xChangeIDKeyword) return false;
          } else {
            // Handle cases with "t" or "p" prefixes followed by a number
            if ((keyword.startsWith("t") || keyword.startsWith("p")) && !isNaN(keyword.slice(1))) {
              keyword = Number.parseInt(keyword.replace(/[a-z]/, "")).toString();
            }

            // Common transformations
            const shopTitle = transaction.ShopTitle ? this.lower(transaction.ShopTitle) : "";
            const security = transaction.Security ? this.lower(transaction.Security) : "";
            const email = transaction.Email ? this.lower(transaction.Email) : "";
            const comments = transaction.Comments ? this.lower(transaction.Comments) : "";
            const coinName = transaction.CoinName ? this.lower(transaction.CoinName) : "";
            const longName = transaction.LongName ? this.lower(transaction.LongName) : "";
            const customerRefNr = transaction.CustomerReferenceNr ? this.lower(transaction.CustomerReferenceNr) : "";
            const confirmURL = transaction.ConfirmURL ? this.lower(transaction.ConfirmURL) : "";
            const failURL = transaction.FailURL ? this.lower(transaction.FailURL) : "";
            const invoiceCC = transaction.InvoiceCC ? this.lower(transaction.InvoiceCC) : "";
            const coinAddress = transaction.CoinAddress ? this.lower(transaction.CoinAddress) : "";

            // Numeric transformations
            const amount = this.toFixed(transaction.Amount, 8, "round").toString();
            const fiatConfirmedAmount = this.toFixed(transaction.FiatConfirmedAmount, 8, "round").toString();
            const originalAmountFee = this.toFixed(transaction.OriginalAmountFee, 8, "round").toString();
            const originalAmount = this.toFixed(transaction.OriginalAmount, 8, "round").toString();
            const createdOn = transaction.CreatedOn.toString();

            // Filtering based on keyword match
            const matchesKeyword = (
              this._.includes(shopTitle, keyword) ||
              this._.includes(transaction.TransactionID.toString(), keyword) ||
              this._.includes(transaction.MerchantID.toString(), keyword) ||
              this._.includes(transaction.Tag, keyword) ||
              this._.includes(security, keyword) ||
              this._.includes(email, keyword) ||
              this._.includes(comments, keyword) ||
              this._.includes(coinName, keyword) ||
              this._.includes(longName, keyword) ||
              this._.includes(customerRefNr, keyword) ||
              this._.includes(amount, keyword) ||
              this._.includes(fiatConfirmedAmount, keyword) ||
              this._.includes(originalAmountFee, keyword) ||
              this._.includes(confirmURL, keyword) ||
              this._.includes(failURL, keyword) ||
              this._.includes(invoiceCC, keyword) ||
              this._.includes(coinAddress, keyword) ||
              this._.includes(originalAmount, keyword) ||
              this._.includes(createdOn, keyword)
            );

            if (!matchesKeyword) return false;
          }
        }

        return true;
      });

      // Filter based on statuses
      if (this.statuses.length) {
        formattedTransactions = formattedTransactions.filter(transaction => {
          if (transaction.Status === "reflextrader") {
            const tIndex = this.statuses.indexOf("rt-t");
            const pIndex = this.statuses.indexOf("rt-p");
            if (tIndex !== -1 || pIndex !== -1) {
              const status = this.statuses[tIndex] || this.statuses[pIndex];
              const prefix = status.split("-")[1];
              return transaction.Prefix.toLowerCase() === prefix;
            }
            return false;
          }
          return this.statuses.includes(transaction.Status);
        });
      }

      // Filter based on types
      if (this.types.length) {
        formattedTransactions = formattedTransactions.filter(transaction => {
          const type = transaction.ExternalTransactionProvider || transaction.TransactionType;
          return this.types.includes(this.lower(type));
        });
      }

      // Filter based on merchant ID
      if (this.merchantId) {
        formattedTransactions = formattedTransactions.filter(transaction => {
          console.log(transaction.MerchantID, " ", this.merchantId);
          return transaction.MerchantID.toString() === this.merchantId.toString();
        });
      }

      return formattedTransactions;
    },
    paginatedTransactions() {
      if (this.filteredTransactions.length > 0) {
        let filteredTransactions = this.filteredTransactions;
        if (this.sortKey) {
          filteredTransactions = this._.orderBy(filteredTransactions, [this.sortKey], [this.order]);
        }
        return filteredTransactions.slice(
          this.offset * this.limit,
          this.currentPage * this.limit,
        );
      }
      return this.filteredTransactions;
    },
    offset() {
      return this.currentPage > 0 ? this.currentPage - 1 : 0;
    },
    meta() {
      return {
        total: this.filteredTransactions.length,
        offset: this.offset * this.limit,
        limit: this.limit,
        page: this.currentPage,
      };
    },
    merchants() {
      let merchants = [];
      this.merchantsList.forEach(m => {
        merchants.push({
          ID: m.ID,
          Name: m.ShopTitle + " (" + m.Name + ")",
        });
      });
      merchants = this._.orderBy(merchants, [m => m.Name.toLowerCase()], ["ASC"]);
      merchants.unshift({
        ID: "",
        Name: "All Merchants",
      });
      return merchants;
    },
    isInSync() {
      if (this.syncStatus) {
        return this.syncStatus.Seconds_Behind_Master < 5 &&
          this.syncStatus.Slave_IO_Running.toLowerCase() === "yes" &&
          this.syncStatus.Slave_SQL_Running.toLowerCase() === "yes";
      }
      return true;
    },
  },
  watch: {
    isClientSearch(val) {
      if (val) {
        this.keyword = "";
        this.merchantId = "";
      }
    },
    searchKeyword(val) {
      this.filters.keyword = val;
    },
    $route(to, from) {
      if (to.query.reload) {
        this.reload = true;
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });
        this.filters.range = {
          startDate: this.tz().subtract(6, "days").format("YYYY-MM-DD"),
          endDate: this.tz().format("YYYY-MM-DD"),
        };
        this.searchLatestInfo();
        this.getLast24HData();
        this.getTotalRegistrationIn24H();
        this.getRecentRegistrations();
        this.getLatestKYC();
      }
    },
  },
  methods: {
    lower(val) {
      return val ? val.toLowerCase() : "";
    },
    isAuthorized(p) {
      return isAuthorized(p);
    },
    resetSearch() {
      this.currentPage = 1;
      this.isClientSearch = false;
      this.resetStatuses = new Date().getTime();
      this.filters = {
        keyword: "",
        merchantId: "",
        range: {
          startDate: this.tz().subtract(6, "days").format("YYYY-MM-DD"),
          endDate: this.tz().format("YYYY-MM-DD"),
        },
      };

      setTimeout(() => {
        this.searchLatestInfo();
      }, 50);
    },
    disabledDates(date) {
      return date > new Date();
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    shortNameToID(shortName) {
      const coin = this._.find(this.altCoins, { ShortName: shortName });
      return coin ? coin.ID : 625;
    },
    copyToClipboard(val, isInvoice = false) {
      if (isInvoice) {
        val = "https://cointopay.com/invoice/" + val;
      }
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    updatePage(page) {
      this.currentPage = page;
    },
    updateFilters(statuses) {
      this.currentPage = 1;
      this.filters.statuses = statuses.map(s => s.id);
    },
    updateStatusFilters(statuses) {
      this.currentPage = 1;
      this.statuses = statuses.map(s => s.id);
    },
    updateTypeFilters(types) {
      this.currentPage = 1;
      this.types = types.map(s => s.id);
    },
    fs(status) {
      return formatStatus(status);
    },
    fd(val) {
      return formatDate(val);
    },
    dateDiffInDays(d1, d2) {
      return dateDiffInDays(d1, d2, false);
    },
    toFixed(val, dec = 8, rounding = "floor") {
      return toFixed(val, dec, rounding);
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.transactionsList = this.transactionsList.map(t => {
        return {
          ...t,
          isNew: false,
        };
      });
      this.sortKey = key;
    },
    getPaymentProviders() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=payment_providers`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        const data = response.data.data;
        this.$store.commit(SET_EXTERNAL_PAYMENT_PROVIDERS, data.externalPaymentProviders);
        this.$store.commit(SET_PAYOUT_TYPES, data.payoutTypes);
      }).catch(error => {
        if (error.data.message.toLowerCase() !== "unauthorized") {
          this.$toast.fire("", error.data.message, "error");
          saveNotification(error.response.data);
        }
      });
    },
    getLatestKYC() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=LatestKYC`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.pendingKYC = response.data.data;
      }).catch(error => {
        if (error.data.message.toLowerCase() !== "unauthorized") {
          this.$toast.fire("", error.data.message, "error");
          saveNotification(error.response.data);
        }
      });
    },
    getLast24HData() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=Last24HData`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        const data = response.data.data;
        this.dashboardOverview24H = {
          ...this.dashboardOverview24H,
          ...data,
        };
      }).catch(error => {
        if (error.data.message.toLowerCase() !== "unauthorized") {
          this.$toast.fire("", error.data.message, "error");
        }
      });
    },
    load24HData() {
      const start = this.tz().subtract(1, "days").format("YYYY-MM-DD");
      const end = this.tz().format("YYYY-MM-DD");
      this.filters.range = {
        startDate: start,
        endDate: end,
      };
      this.searchLatestInfo();
    },
    getRecentRegistrations() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=RecentRegistrations`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.recentRegistrations = response.data.data;
      }).catch(error => {
        if (error.data.message.toLowerCase() !== "unauthorized") {
          this.$toast.fire("", error.data.message, "error");
        }
      });
    },
    getTotalRegistrationIn24H() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=TotalRegistrations24H`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.dashboardOverview24H.TotalRegistrations = response.data.data;
      }).catch(error => {
        if (error.data.message.toLowerCase() !== "unauthorized") {
          this.$toast.fire("", error.data.message, "error");
        }
      });
    },
    savePage() {
      this.$store.commit(SAVE_PAGE, this.currentPage);
    },
    searchLatestInfo() {
      if (this.filters.range?.startDate !== "" && this.filters.range?.endDate !== "") {
        if (this.dateDiffInDays(new Date(this.filters.range?.startDate), new Date(this.filters.range?.endDate)) >= 0) {
          if (this.dateDiffInDays(new Date(this.filters.range?.endDate), new Date()) >= 0) {
            this.$store.commit(SAVE_DASHBOARD_FILTERS, this.filters);
            this.fetchMerchantsList();
            this.sync = "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Syncing...";
            if (!this.worker) {
              this.worker = new SyncDashboard();
            }
            this.worker.addEventListener("message", (e) => {
              if (e.data.action === "fetchLatestInfoComplete") {
                this.sync = "";
                this.transactionsList = e.data.transactionsList;
                this.$store.commit(SAVE_TRANSACTIONS, e.data.transactionsList);
                // Stop worker
                this.worker.postMessage("stop");
                this.worker = null;
              } else if (e.data.action === "fetchLatestInfoError") {
                this.$toast.fire("", e.data.error, "error");
              }
            });
            window.setTimeout(() => {
              this.worker.postMessage({
                action: "fetchLatestInfo",
                start: this.filters.range.startDate,
                end: this.filters.range.endDate,
                keyword: this.filters.keyword,
                statuses: this.filters.statuses,
                merchantId: this.filters.merchantId,
                jwtToken: this.jwtToken,
              });
            }, 150);
          } else {
            this.$toast.fire("", "To date can not be greater than today", "warning");
          }
        } else {
          this.$toast.fire("", "From date can not be greater than To date", "warning");
        }
      }
    },
    getTransactionDate(t) {
      let date = t.CreatedOn;
      if (t.Status.toLowerCase() === "expired") {
        date = t.Expired;
      } else if (["paid", "underpaid"].indexOf(t.Status.toLowerCase()) !== -1) {
        date = t.TransactionConfirmedOn;
      }
      return date;
    },
    getTransaction(id, isNew = false) {
      const url = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${url}/?Call=TransactionDetail`, {
        params: {
          ID: id,
        },
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.status === 200) {
          let data = response.data.data;
          // trim char part from id and convert it to number
          id = id.replace(/[a-z]/i, "");
          id = Number(id);
          const index = this._.findIndex(this.transactions, { TransactionID: id });
          this.transactionsList = this.transactions.map(t => {
            return {
              ...t,
              isNew: false,
            };
          });
          if (index !== -1) {
            let transaction = this.transactionsList[index];
            transaction = Object.assign({}, transaction, {
              Status: data.Status,
              TransactionConfirmedOn: data.TransactionConfirmedOn,
              Date: this.getTransactionDate(data),
              isNew: isNew,
            });
            this.transactionsList.splice(index, 1, transaction);
          } else {
            // Check if it meets the filter criteria
            if (moment(this.filters.range.endDate).isSame(moment(data.CreatedOn).valueOf(), "day") &&
              (!this.filters.merchantId || this.filters.merchantId.toString() === data.MerchantID.toString()) &&
              this.filters.statuses.indexOf(data.Status.toLowerCase()) !== -1) {
              data = {
                ...data,
                Date: this.getTransactionDate(data),
                isNew: isNew,
              };
              this.transactionsList.splice(0, 0, data);
            }
          }
        }
      }).catch(error => {
        if (error && error.data && error.data.message) {
          if (error.data.message.toLowerCase() !== "unauthorized") {
            this.$toast.fire("", error.data.message, "error");
            saveNotification(error.data.message);
          }
        }
      });
    },
    checkSyncStatus() {
      this.isSyncStatusLoading = true;
      const url = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${url}/?Call=SlaveStatus`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.isSyncStatusLoading = false;
        this.syncStatus = response.data.data;
      }).catch(_error => {
        this.isSyncStatusLoading = false;
      });
    },
    checkServletStatus(key) {
      const urls = {
        artemis: "https://artemis.cointopay.com/admin/status",
        kucoin: "https://artemis.cointopay.com/kucoin/status",
        kucoinSockets: "https://connect.durpbox.com:9443/kucoin-sockets/status",
        kucoinBroker: "https://artemis.cointopay.com/kucoin-broker/status",
      };
      this.statusCheckLoaders[key] = true;
      this.$http.get(urls[key]).then(response => {
        this.statusCheckLoaders[key] = false;
        this.statusCheck[key] = response.data && response.data.trim() === "OK";
      }).catch(_error => {
        this.statusCheckLoaders[key] = false;
        this.statusCheck[key] = false;
      });
    },
    launchTransactionStatusModal(transaction) {
      this.$bus.$emit("update-transaction-status-modal", {
        title: "Update Transaction Status",
        data: transaction,
        confirm: () => {
          const id = transaction.Prefix + transaction.TransactionID.toString().padStart(9, "0");
          this.getTransaction(id);
        },
      });
    },
    launchUpdateTransactionLogsModal(transaction, index) {
      this.$bus.$emit("update-transaction-logs-modal", {
        title: "Update Transaction Logs",
        data: transaction,
        confirm: (comments, paymentProviderID) => {
          const data = (transaction.Prefix === "T") ? this.externalPaymentProviders : this.payoutTypes;
          const pp = this._.find(data, { ID: paymentProviderID });
          if (pp) {
            const index = this._.findIndex(this.transactions, { TransactionID: transaction.TransactionID });
            if (index !== -1) {
              transaction = Object.assign({}, transaction, {
                ExternalTransactionProvider: pp.Name,
                Comments: comments,
              });
              this.transactions.splice(index, 1, transaction);
            }
          }
        },
      });
    },
    getRevolutTransactionDetail(confirmCode) {
      const url = "https://tippr.today:9443/invoice-payment/?call=orderDetail&confirmCode=" + confirmCode;
      this.$http.get(`${url}`).then(response => {
        this.$bus.$emit("revolut-result-modal", {
          title: "Detail",
          data: response.data.data,
        });
      }).catch(error => {
        console.log(error);
      });
    },
    getRapydTransactionDetail(transaction) {
      const url = `https://tippr.today:9443/rapyd/?action=confirmitjson&ID=${transaction.revolutID}&amount=${transaction.OriginalAmount}&confirm_code=${transaction.Security}`;
      this.$http.get(`${url}`).then(response => {
        this.$bus.$emit("rapyd-result-modal", {
          title: "Detail",
          message: response.data.message,
        });
      }).catch(error => {
        this.$bus.$emit("rapyd-result-modal", {
          title: "Detail",
          message: error.data.message,
        });
      });
    },
    getPayrexxTransactionDetail(transaction) {
      const url = `https://tippr.today:9443/payrexx/?action=retrieve&confirm_code=${transaction.Security}&id=${transaction.revolutID}`;
      this.$http.get(`${url}`).then(response => {
        this.$bus.$emit("rapyd-result-modal", {
          title: "Detail",
          message: response.data.message,
        });
      }).catch(error => {
        this.$bus.$emit("rapyd-result-modal", {
          title: "Detail",
          message: error.data.message,
        });
      });
    },
    getType(transaction) {
      return transaction.ExternalTransactionProvider ? transaction.ExternalTransactionProvider : transaction.TransactionType;
    },
    getRevolutBankTransactionDetail(transaction, isReference = false) {
      const id = !isReference ? transaction.TransactionID : transaction.CustomerReferenceNr;
      const url = "https://app.cointopay.com/revolut?call=transactions&api_key=AABB9fCDE93kd93aakk378djddmcmcnvd483982048272987498789472984792&reference=" + id;
      this.$http.get(`${url}`).then(response => {
        this.$bus.$emit("revolut-bank-result-modal", {
          title: "Detail",
          data: response.data.data,
        });
      }).catch(error => {
        if (error.status === 404 && error.data.message === "No payment information found" && !isReference) {
          this.getRevolutBankTransactionDetail(transaction, true);
        } else {
          this.$bus.$emit("revolut-bank-result-modal", {
            title: "Detail",
            data: [],
            message: error.data.message,
          });
        }
      });
    },
    getBunqTransactionDetail(transaction) {
      const apiKey = "2d743e48c8a03bea86a8d38858b91398fdf3b6d7d38ce6f79034ab61bae8a8365ba9336fd79cf192ed5028d3555621722d743e48c8a03bea86a8d38858b91398fdf3b6d7d38ce6f79034ab61bae8a83693cc208ccb46d66f3fb009932152e6a4";
      const url = `https://app.cointopay.com/bunq?action=receive&api_key=${apiKey}&message=${transaction.TransactionID}&amount=${transaction.OriginalAmount.toFixed(2)}`;
      this.$http.get(`${url}`, {
        withCredentials: true,
      }).then(response => {
        this.$bus.$emit("bunq-result-modal", {
          title: "Detail",
          data: response.data.map ? response.data.map : null,
          message: "",
        });
      }).catch(error => {
        this.$bus.$emit("bunq-result-modal", {
          title: "Detail",
          data: null,
          message: error.data.message,
        });
        console.log(error.data.message);
      });
    },
    getSquareTransactionDetail(transaction) {
      const url = "https://tippr.today:9443/sup/?Call=searchById&id=" + transaction.revolutID;
      this.$http.get(`${url}`, {
        withCredentials: true,
      }).then(response => {
        this.$bus.$emit("square-result-modal", {
          title: "Detail",
          data: response.data.data ? response.data.data : null,
          message: "",
        });
      }).catch(error => {
        this.$bus.$emit("square-result-modal", {
          title: "Detail",
          data: null,
          message: error.data.message,
        });
        console.log(error.data.message);
      });
    },
    getStripeTransactionDetail(transaction) {
      const url = "https://ebrighter.com:9443/ctpv2/?call=search&id=" + transaction.revolutID + "&currency=EUR";
      this.$http.get(`${url}`, {
        withCredentials: true,
      }).then(response => {
        this.$bus.$emit("stripe-result-modal", {
          title: "Detail",
          data: response.data.data ? response.data.data : null,
          message: "",
        });
      }).catch(error => {
        this.$bus.$emit("stripe-result-modal", {
          title: "Detail",
          data: null,
          message: "No record found!",
        });
        console.log(error.data.message);
      });
    },
    togglePrivacyMode() {
      this.$store.commit(SET_IS_PRIVACY_MODE, this.privacyMode);
    },
    isStatusPaid(transaction) {
      const status = transaction.Status.toLowerCase();
      if (status === "waiting" || status === "expired") {
        return false;
      }
      return true;
    },
    fetchMerchantsList() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=MerchantsListDash`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          startDate: this.dashboardFilters?.range?.startDate || this.tz().subtract(60, "days").format("YYYY-MM-DD"),
          endDate: this.dashboardFilters?.range?.endDate || this.tz().format("YYYY-MM-DD"),
        },
      }).then(response => {
        this.merchantsList = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    initSocketTransactions() {
      const self = this;
      this.socketTransactions = new WebSocket("wss://wss.cointopay.com/transactions");
      this.socketTransactions.onmessage = (event) => {
        if (event) {
          if (event.data) {
            const parts = event.data.split(":");
            if (parts.length >= 4 && parts[2].toLowerCase() === "waiting") {
              const transactionId = "T" + parts[1].toString().padStart("0", 9);
              self.getTransaction(transactionId, true);
            }
          }
        }
      };
      this.socketTransactions.onclose = (event) => {
        if (!self.isCloseSocketTransactions) {
          try {
            self.initSocketTransactions();
          } catch (error) {
            console.log("Wallet was not able to open a WebSocket connection to transactions");
          }
        }
      };
    },
    refreshServletStatus() {
      Object.keys(this.statusCheck).forEach(key => {
        this.checkServletStatus(key);
      });
    },
  },
  mounted() {
    this.fetchMerchantsList();
    this.privacyMode = this.isPrivacyMode;
    this.checkSyncStatus();
    this.refreshServletStatus();
    this.serlvetsStatusTimer = setInterval(() => {
      this.refreshServletStatus();
    }, 5 * 60000);
    let isSearching = false;
    this.transactionsList = this.transactions;
    if (this.dashboardFilters && this.dashboardFilters.range !== null) {
      this.filters = this.dashboardFilters;
    }
    if (this.$route.query.searching) {
      isSearching = true;
      this.filters.keyword = this.searchKeyword;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    if (this.$route.query.search) {
      isSearching = true;
      this.filters.keyword = this.$route.query.search;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    if (this.$route.query.reload) {
      this.reload = true;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    if (this.$route.query.xc) {
      this.filters.keyword = this.$route.query.xc;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
      this.filters.range = {
        startDate: this.tz().subtract(60, "days").format("YYYY-MM-DD"),
        endDate: this.tz().format("YYYY-MM-DD"),
      };
      this.searchLatestInfo();
    }
    if (this.page && this.transactions.length > 0 && !this.reload) {
      this.updatePage(this.page);
      this.transactionsList = this._.cloneDeep(this.transactions);
      this.$store.commit(STOP_LOADING);
    } else {
      this.searchLatestInfo();
    }
    this.getLast24HData();
    this.getLatestKYC();
    this.getRecentRegistrations();
    this.getTotalRegistrationIn24H();
    this.initSocketTransactions();
    // Get roles
    this.$store.dispatch(ROLES).catch(() => {
    });
    if (!isSearching) {
      // document.getElementById('search').focus()
    }
    this.getPaymentProviders();
    const self = this;
    $(document).on("keydown", (e) => {
      if (e.key === "F2") {
        self.dashboardFilters.range.endDate = self.tz().format("YYYY-MM-DD");
        self.getLast24HData();
        self.getLatestKYC();
        self.getRecentRegistrations();
        self.getTotalRegistrationIn24H();
        document.getElementById("search").focus();
        return "";
      }
    });
  },
  beforeDestroy() {
    this.destroyed = true;
    if (this.socketTransactions) {
      this.isCloseSocketTransactions = true;
      this.socketTransactions.close();
    }
    if (this.serlvetsStatusTimer !== null) {
      clearInterval(this.serlvetsStatusTimer);
      this.serlvetsStatusTimer = null;
    }
  },
};
</script>

<style scoped lang="scss">
.text-dark-gray {
  color: #878889;
}
</style>
