import * as _ from "lodash";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "merchantPermissions",
    ]),
  },
  methods: {
    isAuthorized(permission) {
      const permissions = this.merchantPermissions;
      if (permission) {
        return _.findIndex(permissions, { Slug: permission }) !== -1;
      }
      return true;
    },
  },
};
