require("summernote");

export default {
  replace: true,
  inherit: false,
  template: "<textarea class='form-control' :name='name'></textarea>",
  props: {
    model: {
      required: true,
      twoWay: true,
    },
    language: {
      type: String,
      required: false,
      default: "en-US",
    },
    height: {
      type: Number,
      required: false,
      default: 160,
    },
    minHeight: {
      type: Number,
      required: false,
      default: 160,
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 800,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    toolbar: {
      type: Array,
      required: false,
      default: function () {
        return [
          ["style", ["bold", "italic", "underline", "clear"]],
          ["font", ["strikethrough", "superscript", "subscript"]],
          // ['fontname', ['fontname']],
          ["fontsize", ["fontsize"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture"]],
          ["view", ["fullscreen", "codeview"]],
        ];
      },
    },
  },
  methods: {
    updateModel() {
      let code = "";
      if (!this.isChanging) {
        this.isChanging = true;
        code = this.control.summernote("code");
        this.$emit(
          "update:model",
          code === null || code.length === 0 ? null : code,
        );
        this.$nextTick(function () {
          this.isChanging = false;
        });
      }
      this.$parent.text = code;
    },
    sendFile(file) {
      if (file.type.startsWith("image/")) {
        const fd = new FormData();
        fd.append("file123", file);
        fd.append("Price", 1);
        // Upload to amazon S3
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$http
          .post(`${apiBaseUrl}/MarketAPI?POSBLOB`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            if (response.status === 200) {
              const image = $("<img src=\"\" alt=\"\">").attr("src", response.data);
              $(this.$el).summernote("insertNode", image[0]);
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        // Clear file
        this.$toast.fire("", "Please provide a valid image file", "error");
      }
    },
  },
  created: function () {
    this.isChanging = false;
    this.control = null;
  },
  mounted: function () {
    //  initialize the summernote
    if (this.minHeight > this.height) {
      this.minHeight = this.height;
    }
    if (this.maxHeight < this.height) {
      this.maxHeight = this.height;
    }
    const self = this;
    this.control = $(this.$el);
    this.control.summernote({
      lang: this.language,
      height: this.height,
      minHeight: this.minHeight,
      maxHeight: this.maxHeight,
      toolbar: this.toolbar,
      callbacks: {
        onInit: function () {
          self.control.summernote("code", self.model);
          const elm = $(this);
          if (elm.closest(".modal").length > 0) {
            elm
              .next()
              .find(".dropdown-toggle")
              .dropdown();
          }
        },
        onChange: function () {
          self.updateModel();
        },
        onChangeCodeview: function () {
          self.updateModel();
        },
        onImageUpload: function (files) {
          self.sendFile(files[0]);
        },
      },
    });
  },
  watch: {
    model: function (val) {
      if (!this.isChanging) {
        this.isChanging = true;
        const code = val === null ? "" : val;
        this.control.summernote("code", code);
        this.isChanging = false;
      }
    },
  },
  destroyed() {
    $(this.$el).summernote("destroy");
  },
};
