<template>
  <div id="app" style="position: relative">
    <div class=" wrapper">
      <router-view />
    </div>
    <Chat v-if="isAuthorized('chatter-role')"></Chat>
  </div>
</template>
<script>
import store from "./store/index.js";
import { mapGetters } from "vuex";
import { SAVE_DASHBOARD_FILTERS, SAVE_NOTIFICATIONS } from "./store/keys";
import Chat from "@/components/Chat.vue";
import { isAuthorized } from "@/lib/utils";

export default {
  name: "app",
  components: {
    Chat,
  },
  data() {
    return {
      timer: null,
      isCloseNotificationsSocket: false,
      notificationsSocket: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "notifications",
      "dashboardFilters",
    ]),
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    keepSessionActive() {
      this.timer = setInterval(() => {
        if (this.currentUser) {
          this.getUserData();
        }
      }, 1000 * 300);
    },
    getUserData() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const params = {
        Call: "Accountdetail",
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: params,
        withCredentials: true,
      }).then(response => { }).catch(() => { });
    },
    initNotificationsSocket() {
      this.notificationsSocket = new WebSocket("wss://wss.cointopay.com/balance");
      const self = this;
      this.notificationsSocket.onmessage = (event) => {
        if (event) {
          if (event.data) {
            const parts = event.data.split(":");
            if (parts.length === 4) {
              const notifications = self.notifications;
              const notification = self.generateNotification(parts);
              notifications.splice(0, 0, notification);
              self.$store.commit(SAVE_NOTIFICATIONS, notifications);
            }
          }
        }
      };
      this.notificationsSocket.onclose = (event) => {
        if (!self.isCloseNotificationsSocket) {
          self.initNotificationsSocket();
        }
      };
    },
    generateNotification(parts) {
      let message = "";
      let prefix = "T";
      const id = prefix + parts[1].padStart(9, "0");
      switch (parts[2]) {
        case "PAID":
          prefix = "T";
          message = `${id} paid successfully`;
          break;
        case "EXPIRED":
          prefix = "T";
          message = `${id} has expired`;
          break;
        case "PAYOUT":
          prefix = "P";
          message = `${id} payout successful`;
      }
      return {
        message: message,
        id: id,
        date: new Date(),
      };
    },
    awake() {
      let lastTime = (new Date()).getTime();
      const checkInterval = 10000;
      setInterval(() => {
        const currentTime = (new Date()).getTime();
        if (currentTime > (lastTime + checkInterval * 2)) {
          if (this.$route.name === "dashboard") {
            if (this.dashboardFilters.range.endDate < this.tz().format("YYYY-MM-DD")) {
              const range = {
                ...this.dashboardFilters.range,
                endDate: this.tz().format("YYYY-MM-DD"),
              };
              this.$store.commit(SAVE_DASHBOARD_FILTERS, {
                ...this.dashboardFilters,
                range: range,
              });
            }
            window.location.reload();
          }
        }
        lastTime = currentTime;
      }, checkInterval);
    },
    checkStorageQuota() {
      if ("storage" in navigator && "estimate" in navigator.storage) {
        navigator.storage.estimate().then(estimate => {
          const quota = (estimate.quota / (1024 * 1024)).toFixed(2) || 0; // Assign quota or default to 0 if undefined
          const usage = (estimate.usage / (1024 * 1024)).toFixed(2) || 0; // Assign usage or default to 0 if undefined
          console.log("quota: " + quota);
          console.log("usage: " + usage);
          this.percentageUsed = ((usage / quota) * 100).toFixed(2); // Calculate percentage used
        }).catch(err => {
          console.error("Error estimating storage:", err);
        });
      } else {
        console.warn("The Storage Manager API is not supported in this browser.");
      }
    },
  },
  created() {
    this.keepSessionActive();
    this.initNotificationsSocket();
  },
  mounted() {
    this.awake();
    this.checkStorageQuota();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.notificationsSocket) {
      this.isCloseNotificationsSocket = true;
      this.notificationsSocket.close();
    }
  },
  store,
};
</script>
<style lang="scss">
@import '~sweetalert2/src/sweetalert2';
@import '~@sweetalert2/theme-bootstrap-4/bootstrap-4';
@import "~vue-select/src/scss/vue-select";
@import "~bootstrap-select/sass/bootstrap-select";
@import './assets/scss/styles';

.swal2-popup {
  font-size: 1.6rem !important;
}

.table {
  thead {
    tr {
      th {
        border-top: 2px solid #f4f4f4 !important;
      }
    }
  }
}

a {
  cursor: pointer;
}
</style>
