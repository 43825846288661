<template>
  <div class="dropdown ml-8">
    <a class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
      {{ statuses.filter(s => s.selected).length }} options selected
    </a>
    <div class="dropdown-menu">
      <form>
        <a class="dropdown-item" href="#">
          <label class="d-block">
            <input type="checkbox" class="form-checkbox" v-model="allStatuses" :checked="allStatuses" />
            <span class="ml-3">Select All</span>
          </label>
        </a>
        <a class="dropdown-item" href="#" v-for="(status, index) in statuses" :key="index">
          <label class="d-block">
            <input type="checkbox" class="form-checkbox" v-model="status.selected" :checked="status.selected" />
            <span class="ml-3">{{ status.label }}</span>
          </label>
        </a>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: "StatusesDropdown",
  data() {
    return {
      statuses: [
        { id: "waiting", label: "Waiting", selected: true },
        { id: "expired", label: "Expired", selected: true },
        { id: "paid", label: "Paid", selected: true },
        { id: "underpaid", label: "Underpaid", selected: true },
        { id: "rt-t", label: "Reflextrader Transaction", selected: false },
        { id: "rt-p", label: "Reflextrader Payout", selected: false },
        { id: "vtoken-active", label: "vToken Active", selected: true },
        { id: "vtoken-claimed", label: "vToken Claimed", selected: true },
        { id: "ltr-open", label: "LTR Open", selected: true },
        { id: "ltr-closed", label: "LTR Closed", selected: true },
        { id: "ltr-expired", label: "LTR Expired", selected: true },
        { id: "payout completed", label: "Payout Completed", selected: true },
        { id: "payout pending", label: "Payout Pending", selected: true },
        { id: "payout cancelled", label: "Payout Cancelled", selected: true },
        { id: "swap", label: "Swap", selected: true },
      ],
      allStatuses: true,
    };
  },
  props: {
    reset: {
      type: Number,
      default: null,
    },
  },
  watch: {
    statuses: {
      handler(newVal) {
        this.$emit("statuses", newVal.filter(s => s.selected));
      },
      deep: true,
    },
    allStatuses(newVal) {
      this.statuses = this.statuses.map(s => {
        return {
          ...s,
          selected: newVal,
        };
      });
      this.$emit("statuses", this.statuses.filter(s => s.selected));
    },
  },
  beforeMount() {
    this.statuses = this.statuses.map(s => {
      return {
        ...s,
        selected: true,
      };
    });
  },
};
</script>

<style scoped lang="scss">
.dropdown-menu {
  padding: 4px 8px;
  min-width: 220px;

  a {
    display: block;
    cursor: pointer;
    margin-bottom: 1px;

    label {
      margin: 0;
      padding: 2px 4px;
      cursor: pointer;
      color: #404040;
      font-weight: normal;
    }

    :hover {
      background-color: #efefef;
    }
  }
}
</style>
