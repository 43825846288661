var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"login-box-msg"},[_vm._v("Sign in to start your session")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"username","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.User),expression:"form.User"}],staticClass:"form-control",attrs:{"aria-label":"Username","placeholder":"Username","type":"text"},domProps:{"value":(_vm.form.User)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "User", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"password","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Password),expression:"form.Password"}],staticClass:"form-control",attrs:{"aria-label":"Password","autocomplete":"off","placeholder":"Password","type":"password"},domProps:{"value":(_vm.form.Password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Password", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"2 factor authentication","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.totp),expression:"form.totp"}],staticClass:"form-control",attrs:{"aria-label":"2 factor authentication","autocomplete":"off","placeholder":"2 factor authentication","type":"text"},domProps:{"value":(_vm.form.totp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "totp", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"rules":"reCaptcha","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-recaptcha',{ref:"reCaptcha",attrs:{"sitekey":_vm.siteKey},on:{"reset":_vm.reset,"verify":_vm.verify},model:{value:(_vm.form.reCaptcha),callback:function ($$v) {_vm.$set(_vm.form, "reCaptcha", $$v)},expression:"form.reCaptcha"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-8"},[_c('router-link',{attrs:{"to":{ name: 'forgot-password' },"tag":"a"}},[_vm._v("Forgot Your Password?")])],1),_c('div',{staticClass:"col-xs-4"},[_c('button',{staticClass:"btn btn-success btn-block btn-flat",attrs:{"disabled":_vm.isLoading,"type":"submit"}},[(!_vm.isLoading)?_c('span',[_vm._v("Login")]):_vm._e(),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin"})]):_vm._e()])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }