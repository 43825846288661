<template>
  <div class="row hidden-c">
    <div class="col-lg-10 flex align-items-center justify-between">
      <div class="input-group flex-grow-1">
        <div class="input-group-btn">
          <button class="btn btn-default btn-flat" type="button" @click="copyToClipboard(form.source)">
            <i class="fa fa-clipboard"></i>
          </button>
        </div>
        <input ref="source" v-model="form.source" aria-label="" class="form-control" type="text"
          @keyup="calculate(true)" />
        <div class="input-group-btn" id="source-dropdown">
          <button aria-expanded="false" aria-haspopup="true" class="btn btn-flat btn-default dropdown-toggle"
            data-toggle="dropdown" type="button" @click="focus('source')">
            <img :src="generateIconUrl(sourceAltCoin)" alt="" class="w-20" />
            <span class="ml-8 mr-8" v-if="sourceAltCoin">{{ sourceAltCoin.LongName }}</span>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right dropdown-wide">
            <li class="px-15 py-4">
              <input ref="sourceKeyword" v-model="keyword" aria-label="" class="form-control form-control-sm"
                type="text" @keydown.enter="setSourceID(null)" />
              <div class="clearfix"></div>
            </li>
            <li>
              <div class="divider"></div>
            </li>
            <li v-for="(record, index) in filteredSourceAltCoins" :key="index">
              <a class="dropdown-item" href="javascript:void(0)" @click="setSourceID(record.ID)">
                <img :src="generateIconUrl(record)" alt="" class="w-20" />
                <span class="ml-8">{{ record.LongName + ' - ' + record.Name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mx-24">
        <!--<i class="fa fa-exchange fa-2x text-gray-dark pointer" @click="swap()"></i>-->
      </div>
      <div class="input-group flex-grow-1">
        <div class="input-group-btn">
          <button class="btn btn-default btn-flat" type="button" @click="copyToClipboard(form.target)">
            <i class="fa fa-clipboard"></i>
          </button>
        </div>
        <input v-model="form.target" aria-label="" class="form-control" type="text" ref="target"
          @keyup="calculate(false)" />
        <div class="input-group-btn" id="target-dropdown">
          <button aria-expanded="false" aria-haspopup="true" class="btn btn-flat btn-default dropdown-toggle"
            data-toggle="dropdown" type="button" @click="focus('target')">
            <img :src="generateIconUrl(targetAltCoin)" alt="" class="w-20" />
            <span class="ml-8 mr-8" v-if="targetAltCoin">{{ targetAltCoin.LongName }}</span>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right dropdown-wide">
            <li class="px-15 py-4">
              <input aria-label="" ref="targetKeyword" v-model="targetKeyword" class="form-control form-control-sm"
                type="text" @keydown.enter="setTargetID(null)" />
              <div class="clearfix"></div>
            </li>
            <li>
              <div class="divider"></div>
            </li>
            <li v-for="(record, index) in filteredTargetAltCoins" :key="index">
              <a class="dropdown-item" href="javascript:void(0)" @click="setTargetID(record.ID)">
                <img :src="generateIconUrl(record)" alt="" class="w-20" />
                <span class="ml-8">{{ record.LongName + ' - ' + record.Name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div style="margin-left: 2rem">
        <i class="fa fa-refresh pointer" @click="reset()"></i>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { generateIconUrl, saveNotification, toFixed } from "@/lib/utils";
import { SAVE_CONVERTER } from "@/store/keys";

export default {
  name: "Converter",
  data() {
    return {
      form: this.$store.getters.converter,
      fiatValue: 0,
      isSwapped: false,
      keyword: "",
      targetKeyword: "",
      isReverse: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "onlineAltCoins",
      "converter",
    ]),
    sourceAltCoin() {
      if (this.form.sourceAltCoinId) {
        return this._.find(this.onlineAltCoins, { ID: this.form.sourceAltCoinId });
      }
      return null;
    },
    targetAltCoin() {
      if (this.form.targetAltCoinId) {
        return this._.find(this.onlineAltCoins, { ID: this.form.targetAltCoinId });
      }
      return null;
    },
    filteredSourceAltCoins() {
      const altCoins = [...this.onlineAltCoins];
      return altCoins.filter(coin => {
        if (this.keyword) {
          const keyword = this.keyword.trim().toLowerCase();
          return (this._.includes(coin.LongName.toLowerCase(), keyword) ||
            this._.includes(coin.ShortName.toString().toLowerCase(), keyword) ||
            this._.includes(coin.Name.toString().toLowerCase(), keyword));
        }
        return true;
      });
    },
    filteredTargetAltCoins() {
      const altCoins = [...this.onlineAltCoins];
      return altCoins.filter(coin => {
        if (this.targetKeyword) {
          const keyword = this.targetKeyword.trim().toLowerCase();
          return (this._.includes(coin.LongName.toLowerCase(), keyword) ||
            this._.includes(coin.ShortName.toString().toLowerCase(), keyword) ||
            this._.includes(coin.Name.toString().toLowerCase(), keyword));
        }
        return true;
      });
    },
  },
  watch: {
    "form.sourceAltCoinId"(val) {
      this.fiatLookup();
      this.$store.commit(SAVE_CONVERTER, this.form);
    },
    "form.targetAltCoinId"(val) {
      this.fiatLookup();
      this.$store.commit(SAVE_CONVERTER, this.form);
    },
    "converter"(val) {
      this.form = { ...val };
      this.fiatLookup();
    },
    "form.source"(val) {
      if (val && val.toString().trim().indexOf(",") !== -1) {
        this.form.source = val.replace(",", ".");
        this.$store.commit(SAVE_CONVERTER, this.form);
      }
    },
    "form.target"(val) {
      if (val && val.toString().trim().indexOf(",") !== -1) {
        this.form.target = val.replace(",", ".");
        this.$store.commit(SAVE_CONVERTER, this.form);
      }
    },
  },
  methods: {
    focus(type) {
      setTimeout(() => {
        if (type === "source") {
          this.keyword = "";
          this.$refs.sourceKeyword.focus();
        } else {
          this.targetKeyword = "";
          this.$refs.targetKeyword.focus();
        }
      }, 50);
    },
    setSourceID(id) {
      if (id) {
        this.form.sourceAltCoinId = id;
      } else if (this.filteredSourceAltCoins.length > 0) {
        this.form.sourceAltCoinId = this.filteredSourceAltCoins[0].ID;
        $("#source-dropdown").removeClass("open");
      }
      setTimeout(() => {
        this.$refs.source.focus();
      }, 50);
    },
    setTargetID(id) {
      if (id) {
        this.form.targetAltCoinId = id;
      } else if (this.filteredTargetAltCoins.length > 0) {
        this.form.targetAltCoinId = this.filteredTargetAltCoins[0].ID;
        $("#target-dropdown").removeClass("open");
      }
      setTimeout(() => {
        this.$refs.target.focus();
      }, 50);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    reset() {
      this.fiatLookup();
      saveNotification("Rate refreshed");
      this.$toast.fire("", "Rate refreshed", "success");
    },
    generateIconUrl(record) {
      if (!record) {
        return "";
      }
      return generateIconUrl(record.Name);
    },
    fiatLookup() {
      this.isReverse = false;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const targetCurrency = this.targetAltCoin ? this.targetAltCoin.ShortName : "EUR";
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Fiatlookup",
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          AltCoinID: this.form.sourceAltCoinId,
          Amount: 1,
          FiatLookup: 0,
          FiatMoney: targetCurrency,
          output: "json",
        },
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.fiatValue = response.data[0].OutputValue;
          this.calculate();
        } else {
          this.reverseFiatLookup();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    reverseFiatLookup() {
      this.isReverse = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const sourceCurrency = this._.find(this.onlineAltCoins, { ID: this.form.sourceAltCoinId });
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "Fiatlookup",
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          AltCoinID: this.form.targetAltCoinId,
          Amount: 1,
          FiatLookup: 0,
          FiatMoney: sourceCurrency.ShortName,
          output: "json",
        },
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.fiatValue = response.data[0].OutputValue;
          this.calculate();
        } else {
          this.fiatValue = 0;
          this.form.source = "";
          this.form.target = "";
        }
      }).catch(error => {
        console.log(error);
      });
    },
    calculate(isSource = true) {
      if (!this.isReverse) {
        if (isSource) {
          if (this.form.source !== "") {
            this.form.source = this.form.source.toString().replace(/[^\d.,-]/g, "");
            this.form.target = toFixed(Number.parseFloat(this.fiatValue) * Number.parseFloat(this.form.source));
          }
        } else {
          if (this.form.target !== "") {
            this.form.target = this.form.target.toString().replace(/[^\d.,-]/g, "");
            this.form.source = toFixed(Number.parseFloat(this.form.target) / this.fiatValue);
          }
        }
      } else {
        if (isSource) {
          if (this.form.source !== "") {
            this.form.source = this.form.source.toString().replace(/[^\d.,-]/g, "");
            this.form.target = toFixed(Number.parseFloat(this.form.source) / this.fiatValue);
          }
        } else {
          if (this.form.target !== "") {
            this.form.target = this.form.target.toString().replace(/[^\d.,-]/g, "");
            this.form.source = toFixed(Number.parseFloat(this.form.target) * this.fiatValue);
          }
        }
      }
    },
    swap() {
      const form = { ...this.form };
      this.form = {
        source: form.target,
        sourceAltCoinId: form.targetAltCoinId,
        target: form.source,
        targetAltCoinId: form.sourceAltCoinId,
      };
    },
  },
  mounted() {
    if (this.converter === null) {
      this.form = {
        source: 1,
        sourceAltCoinId: 1,
        target: 0,
        targetAltCoinId: 625,
      };
      this.$store.commit(SAVE_CONVERTER, this.form);
    } else {
      this.form = { ...this.converter };
    }
    this.fiatLookup();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wide {
  min-width: 346px;
  min-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.btn-default {
  height: 34px;
}

.p-16 {
  padding: 1rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.mx-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.text-gray-dark {
  color: #808080;
}

.h-full {
  height: 100%;
}

.hidden-c {
  @media (max-width: 576px) {
    display: none;
  }
}
</style>
