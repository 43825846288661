<template>
  <div class="fixed">
    <div class="chat-box" v-show="isOpen">
      <div class="box box-primary">
        <div class="box-header">
          <div class="flex justify-between">
            <h3 class="box-title">Support Chat</h3>
            <button class="btn btn-primary btn-xs block ml-auto" @click="toggleOpen(false)">
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
        <div class="box-body">
          <div class="panel-content">
            <div class="py-2">
              <v-select v-model="targetMerchant" :options="contacts" :reduce="c => c.name" class="ml-8" label="label"
                style="min-width: 200px;"></v-select>
            </div>
            <div class="chat-panel-body">
              <div class="chat-panel-cell">
                <div class="messages-container" ref="messagesContainer" v-if="messages.length > 0">
                  <div class="conversation-box" v-for="message in messages" :key="message.ID">
                    <div class="message" :class="`${message.MsgType === 'OUT' ? 'right' : ''}`">
                      <p class="mb-0">
                        <span class="cursor">
                          <router-link :style="`${message.MsgType === 'OUT' ? 'color: white' : 'color:#404040'}`"
                            :to="{ name: 'user-detail', params: { id: message.FROM.toString() === merchantID.toString() ? 1 : message.FROM } }">
                            {{ message.FROM.toString() === merchantID.toString() ? 'support' : message.ContactName
                            }}:&nbsp;
                          </router-link>
                        </span>
                        <span v-html="message.Message"></span>
                      </p>
                      <p style="font-size: 12px" class="text-right">{{ formatDate(message.CreatedOn) }}</p>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <!--Write message-->
            <div class="apz-row">
              <div class="write b-t">
                <form @keydown="submitOnEnter($event)" @keyup="toggleCtrlStatus($event)">
                  <div class="apz-write-box" :class="{ 'error': !canSend }">
                    <div id="co" ref="apzTextBox" class="apz-text-box cursor-text" focus="true"
                      placeholder="Enter message..." contenteditable="true"></div>
                    <a href="javascript:void(0);" @click="send()" class="btn btn-primary" title="Send Message">Send</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-warning btn-rounded relative" @click="toggleOpen()">
        <span class="flex justify-content-center align-items-center">
          <i class="fa fa-comments-o fa-2x"></i>
          <span class="font-weight-bold ml-4">Chat</span>
        </span>
        <span class="bubble" v-if="total > 0">{{ total }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { mapGetters } from "vuex";
import {
  formatDate,
} from "@/lib/utils";
const moment = require("moment-timezone/builds/moment-timezone-with-data-1970-2030");

export default {
  name: "Chat",
  data() {
    return {
      ctrlDown: false,
      canSend: false,
      messages: [],
      contacts: [],
      targetMerchant: null,
      merchantID: 21901,
      apiKey: null,
      isOpen: false,
      total: 0,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "jwtToken",
    ]),
  },
  watch: {
    targetMerchant(val) {
      if (val) {
        this.loadChatHistory(val);
      }
    },
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.scrollToBottom();
      }
    },
    easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) {
        return (c / 2) * t * t + b;
      }
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    },
    placeCaretAtEnd(el) {
      if (
        typeof window.getSelection !== "undefined" &&
        typeof document.createRange !== "undefined"
      ) {
        const range = document.createRange();
        const textNode = document.createTextNode("\u00a0");
        range.selectNodeContents(el);
        range.collapse(false);
        range.insertNode(textNode);
        range.selectNodeContents(textNode);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof (document.body).createTextRange !== "undefined") {
        const textRange = (document.body).createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    animateScroll(element, currentTime, increment, start, change, duration) {
      currentTime += increment;
      element.scrollTop = this.easeInOutQuad(
        currentTime,
        start,
        change,
        duration,
      );
      if (currentTime < duration) {
        setTimeout(
          this.animateScroll(
            element,
            currentTime,
            increment,
            start,
            change,
            duration,
          ),
          increment,
        );
      }
    },
    scrollTo(element, to, duration) {
      const start = element.scrollTop;
      const change = to - start;
      const increment = 10;
      const currentTime = 0;
      this.animateScroll(
        element,
        currentTime,
        increment,
        start,
        change,
        duration,
      );
    },
    scrollToBottom() {
      setTimeout(() => {
        if (this.$refs.messagesContainer) {
          this.scrollTo(this.$refs.messagesContainer, this.$refs.messagesContainer.scrollHeight, 2000);
        }
      }, 10);
    },
    toggleCtrlStatus(event) {
      const txt = this.$refs.apzTextBox.textContent;
      if (event.which === 17) {
        this.ctrlDown = false;
      }
      if (txt !== "") {
        this.canSend = true;
      }
    },
    formatDate(val) {
      if (val) {
        return formatDate(val, "YYYY-MM-DD HH:mm:ss");
      }
      return val;
    },
    fetchSupportUser() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=UserDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          ID: this.merchantID,
        },
      }).then(response => {
        if (response.status === 200) {
          if (response.data.data) {
            this.apiKey = response.data.data.APIKey;
            this.fetchContacts();
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    fetchContacts() {
      const payload = {
        Call: "CONTACTLIST",
        MerchantID: this.merchantID,
        APIKey: this.apiKey,
        TargetMerchant: "support",
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.get(`${url}/MarketAPI?MSGS`, {
        params: payload,
      }).then(response => {
        let data = response.data.result;
        if (data !== "null") {
          data = data.split(";").filter(Boolean);
          this.contacts = [];
          data.forEach(r => {
            r = r.split("::").filter(Boolean);
            if (r.length === 4) {
              const index = findIndex(this.contacts, { id: r[1] });
              if (index === -1) {
                this.contacts.push({
                  id: r[1],
                  name: r[0],
                  count: r[3],
                  label: `${r[0]} - ${r[1]} ${r[3] > 0 ? "●" : ""}`,
                });
                this.total += Number(r[3]);
              }
            }
          });
          if (this.targetMerchant === null) {
            this.targetMerchant = this.contacts[0].name;
          }
          this.loadChatHistory(this.targetMerchant);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    loadChatHistory(merchant) {
      const payload = {
        Call: "HISTORY",
        MerchantID: this.merchantID,
        APIKey: this.apiKey,
        TargetMerchant: merchant,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.get(`${url}/MarketAPI?MSGS`, {
        params: payload,
      }).then(response => {
        this.messages = response.data;
        this.scrollToBottom();
      }).catch(error => {
        console.log(error);
      });
    },
    submitOnEnter(event) {
      if (event.which === 17) {
        this.ctrlDown = true;
      }
      if (this.ctrlDown && event.which === 13) {
        event.preventDefault();
        if (window.getSelection) {
          const selection = window.getSelection();
          const range = selection.getRangeAt(0);
          const br = document.createElement("br");
          const textNode = document.createTextNode("\u00a0"); // Passing
          range.deleteContents(); // required or not?
          range.insertNode(br);
          range.collapse(false);
          range.insertNode(textNode);
          range.selectNodeContents(textNode);
          selection.removeAllRanges();
          selection.addRange(range);
          return false;
        }
      } else if (event.which === 13) {
        event.preventDefault();
        let message = this.$refs.apzTextBox.textContent;
        if (message.startsWith("@")) {
          let parts = message.split("\u00a0");
          if (parts.length < 2) {
            parts = message.split(" ", 1);
          }
          message = message
            .replace(parts[0], "")
            .replace("\u00a0", "")
            .trim();
        }
        message = message.replaceAll("%C2%A0", " ");
        if (!isEmpty(this.targetMerchant)) {
          this.canSend = false;
          this.sendMessage(message);
        }
      }
    },
    decodeHtml(html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    send() {
      let message = this.$refs.apzTextBox.textContent;
      if (message.startsWith("@")) {
        let parts = message.split("\u00a0");
        if (parts.length < 2) {
          parts = message.split(" ", 1);
          message = message
            .replace(parts[0], "")
            .replace("\u00a0", "")
            .trim();
        }
        this.targetMerchant = parts[0].replace("@", "");
      }
      message = message.replaceAll("%C2%A0", " ");
      this.canSend = false;
      this.sendMessage(message);
    },
    playSound() {
      const audio = new Audio("/msg.mp3");
      audio.play().catch((error) => {
        console.log(error);
      });
    },
    sendMessage(message) {
      message = message.trim();
      if (message.length === 0) {
        this.$toast.fire("", "Please provide a valid message", "error");
      } else {
        message = encodeURIComponent(message);
        message = message.replaceAll("&nbsp;", " ").replaceAll("%C2%A0", " ").replaceAll("%20", " ");
        const payload = {
          Call: "SEND",
          MerchantID: this.merchantID,
          APIKey: this.apiKey,
          TargetMerchant: this.targetMerchant,
          TEXT: decodeURIComponent(message.trim().replace(/\+/g, " ")),
        };
        const url = process.env.VUE_APP_API_URL;
        // const elmTxtArea = document.createElement("textarea");
        // elmTxtArea.value = message;
        this.$http.get(`${url}/MarketAPI?MSGS`, {
          params: payload,
        }).then(response => {
          if (response) {
            const id = response.data.result.split(" ")[2];
            const msg = {
              ContactName: "support",
              CreatedOn: moment.tz("Europe/Amsterdam").format("YYYY-MM-DD HH:mm:ss"),
              FROM: 1,
              ID: id,
              Message: decodeURIComponent(message),
              MsgType: "OUT",
              TO: "",
            };
            this.messages.push(msg);
            this.$refs.apzTextBox.textContent = "";
            this.placeCaretAtEnd(this.$refs.apzTextBox);
            this.canSend = true;
            this.scrollToBottom();
          }
        }, (response) => {
          this.canSend = true;
          if (response.data.error) {
            this.$toast.fire("", response.data.error, "error");
          } else {
            console.log(response);
          }
        });
      }
    },
    initSocket() {
      const self = this;
      const webSocket = new WebSocket("wss://cointopay.com/messages");
      webSocket.onmessage = (evt) => {
        if (evt) {
          evt = evt.data.split(":");
          const id = evt[1];
          if (self.currentUser && Number(id) !== 1) {
            if (Number(evt[2]) === 1) {
              const m = find(self.contacts, { id: id });
              if (m) {
                if (m.name === self.targetMerchant) {
                  self.loadChatHistory(self.targetMerchant);
                } else {
                  self.playSound();
                }
              } else {
                self.playSound();
                self.fetchContacts();
              }
            } else if (Number(evt[2]) === 1) {
              self.loadChatHistory(self.targetMerchant);
            }
          }
        }
      };
      webSocket.onclose = (evt) => {
        self.initSocket();
      };
    },
  },
  beforeMount() {
    this.initSocket();
    this.fetchSupportUser();
  },
  mounted() {
    if (this.$refs.apzTextBox) {
      this.$refs.apzTextBox.addEventListener("paste", (e) => {
        // cancel paste
        e.preventDefault();

        // get text representation of clipboard
        var text = (e.originalEvent || e).clipboardData.getData("text/plain");

        // insert text manually
        document.execCommand("insertHTML", false, text);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 1080;
}

.chat-box {
  width: 360px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.panel-content {
  display: table;
  width: 100%;
  border-spacing: 0;
  outline: 0;
  table-layout: fixed;
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 400px;
}

.chat-panel-body {
  padding: 0;
  position: relative;
  display: table-row;
  height: 100%;
}

.chat-panel-cell {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.messages-container {
  height: 300px;
  position: absolute;
  display: table-row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  box-sizing: border-box;
}

.apz-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.apz-row::after {
  content: "";
  clear: both;
  display: table;
}

ul.list {
  list-style: none;
  padding: 10px;
  min-height: 300px;
  overflow-y: auto;
  margin-bottom: 60px;
  height: calc(100vh - 410px);
}

ul.list>li {
  padding: 8px;
  border-bottom: 1px solid #EBEBEB;
  cursor: pointer;
}

ul.list>li:hover {
  background-color: #456cb4;
  color: #ffffff;
}

.conversation-box {
  font-size: 16px;
  position: relative;
  clear: both;
  margin-bottom: 12px;
  margin-top: 12px;
  vertical-align: top;
  padding: 0 24px;
  word-break: break-word;
}

.message {
  position: relative;
  font-size: 16px;
  min-height: 44px;
  padding: 12px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-animation-name: slideFromLeft;
  animation-name: slideFromLeft;
  border-radius: 16px;
  margin-bottom: 2px;
  max-width: 85%;
  display: inline-block;
  background-color: #EBEBEB;
}

.message.right {
  float: right;
  background-color: #5cb85c;
  color: #ffffff;
}

/* WRITE BOX */

.write {
  width: 100%;
  min-height: 60px;
  padding: 8px 0;
}

.apz-write-box {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px
}

.apz-write-box form {
  width: 100%;
}

.apz-text-box {
  border: 1px solid #EBEBEB;
  padding: 8px;
  width: calc(100% - 70px);
  outline: 0;
  background: transparent;
  font-size: 16px;
  max-height: 80px;
  overflow: auto;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
}

.bubble {
  position: absolute;
  bottom: 30px;
  right: 4px;
  background-color: #DD0000;
  color: #ffffff;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 50%;
}
</style>
