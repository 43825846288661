<template>
  <div class="nav-tabs-custom mb-0">
    <ul :class="tabsClass" class="nav nav-tabs flex-col-sm flex align-items-center">
      <li v-for="(tab, index) in tabList" :key="`tab_${index}`" :class="tab.class" :data="tab">
        <a :href="tab.id" class="flex align-items-center justify-between" data-toggle="tab"
           @click="toggleTab($event, tab.id)"
           v-html="tab.name"></a>
      </li>
      <slot name="nav"></slot>
      <li class="flex-grow-1 text-right">
        <slot name="actions"></slot>
      </li>
    </ul>
    <div :style="padding" class="tab-content">
      <slot class="tab-pane" name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "va-tabs",
  props: {
    tabsClass: {
      type: String,
      default: "",
    },
    isUpdated: {
      type: Number,
      default: 0,
    },
    padding: {
      type: String,
      default: "padding: 10px",
    },
  },
  data() {
    return {
      tabList: [],
    };
  },
  watch: {
    isUpdated(val) {
      for (const i in this.$slots.content) {
        const vm = this.$slots.content[i];
        this.tabList[i].name = vm.data.attrs["data-title"];
      }
    },
  },
  methods: {
    toggleTab(e, id) {
      e.preventDefault();
      e.stopPropagation();
      $("[href=\"" + id + "\"]").tab("show");
      location.hash = id;
      this.$emit("selectedTab", id.replace("#", ""));
    },
  },
  mounted() {
    for (const i in this.$slots.content) {
      const vm = this.$slots.content[i];
      if (typeof vm !== "object") {
        continue;
      }
      vm.elm.className += " tab-pane";
      this.tabList.push({
        id: "#" + vm.data.attrs.id,
        class: vm.data.staticClass || "",
        name: vm.data.attrs["data-title"],
      });
    }
  },
};
</script>
