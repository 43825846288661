<template>
  <div id="bsModalStripeResult" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <template v-if="data">
            <div class="row">
              <div class="col-md-3">ID:</div>
              <div class="col-md-9">
                {{ data.id }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">Amount:</div>
              <div class="col-md-9">{{ (data.amount / 100).toFixed(2) + ' ' + data.currency.toUpperCase() }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Status:</div>
              <div class="col-md-9">{{ data.status }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Description:</div>
              <div class="col-md-9">{{ data.description }}</div>
            </div>
            <div class="row">
              <div class="col-md-3">Receipt:</div>
              <div class="col-md-9">
                <a :href="data.receipt_url" target="_blank">View Receipt</a>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="mb-0">
              {{ message ? message : 'No record found!' }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StripeResultModal",
  data() {
    return {
      title: "",
      data: null,
      message: "",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalStripeResult").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("stripe-result-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      this.message = args.message;
      $("#bsModalStripeResult").modal("show");
    });
  },
};
</script>

<style scoped></style>
